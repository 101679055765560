import "./locations-modal.scss";
import React, { useState, useEffect } from 'react';
// import useCopy from 'use-copy';
import { Link } from "react-router-dom";

export default function LocationsModal(props) {
    const locations = {
        addresses: [
            {
                name: 'Oklahoma',
                address: '3401 Macdonnell Dr, Norman, OK, 73069, United States.',
            },
            {
                name: 'California',
                address: '1900 Lafayette Street, Suite 100, Santa Clara, CA 95050, United States.',
            },
            {
                name: 'Washington, D.C',
                address: '600 14th St NW #507, Washington, DC 20005, USA',
            },

        ],
    }

    // const [address, setAddress] = useState(null);
    // const [copied, copy, setCopied] = useCopy(address);

    // useEffect(() => {
    //     if (address) {
    //       copy(); // Perform the copy action
    //       setCopied(true); // Update the 'copied' state to true
    //     }
    //   }, [address, copy, setCopied]);
    // const copyText = (currentAddress) => {
    //     setAddress(currentAddress); // Update the 'address' state
    //   };

    return (
        <>

            <div class="modal fade modal-lg location-modal" id={props.options.modalName} tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title poppins-regular w-100 text-white" dangerouslySetInnerHTML={{ __html: props.options.title }}></h3>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-center">
                            {locations.addresses.map((value, index) => {
                                return (
                                    <div className="row address-wrapper text-left text-white">
                                        <div className="col-lg-4">
                                            <h6 className="poppins-regular text-left" dangerouslySetInnerHTML={{ __html: value.name }}></h6>


                                        </div>
                                        <div className="col-lg-8">
                                                <div className="div-copy">
                                                    <p>{value.address}</p>
                                                    {/* <span> <i className="fa fa-check-circle text-primary-green"></i></span> */}
                                                </div>
                                        </div>

                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}