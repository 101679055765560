
import Footer from "../footer/footer";
import Nav from "../nav/nav";
import { Link } from "react-router-dom";
import { constants } from "../../constants";
import "./enterprise-solutions.scss";
import { Helmet } from 'react-helmet';
import AnimatedText from "../animated-text/animated-text";

export default function EnterpriseSolutions() {
    const Solutions = {
        enterpriseCapacityManagement: '/solutions/all-solutions/enterprise-solution-capacity-resource-management.png',
        enterpriseSecurityAccess: '/solutions/all-solutions/enterprise-solution-security-access-control.png',
        AIReports: '/solutions/all-solutions/enterprise-solution-ai-driven-reports-ai-network-deployments.png',
        networkDiagnostics: '/solutions/all-solutions/enterprise-solution-network-diagnostics-troubleshooting.png',
        dataCenterInfrastructure: '/solutions/all-solutions/enterprise-solution-data-center-infrastructure-management.png',
    }

    return (
        <div className="bg-white">
            <div>
                <Helmet>
                    <title>Fiber Mountain | Enterprise Solutions</title>
                    <meta name="description" content="Transform your business with our top-quality data center products for mission-critical and enterprise networks." />
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div className="all-solutions-wrapper bg-white">
                {/* All Solutions Hero Section Start */}
                <Nav />
                <div className="hero">
                    <div className="hero-content d-flex justify-content-center align-items-center flex-column position-relative">
                        <h1 className="poppins-regular hero-sub-heading text-white">
                            We Help You <span className="poppins-semi-bold">Grow, Optimize & Thrive</span>
                        </h1>
                        <h6 className="text-white dmsans-regular hero-content-sub-heading">Discover tailored enterprise solutions designed to optimize business operations and drive growth </h6>
                    </div>
                </div>
                <div className="container bg-white">
                    {/* APD STARLING START */}
                    <div className="solution-wrapper bg-pink">
                        <div className="row">
                            <div className="col-lg-6 col-sm-6 poppins-regular content-alignment-center container-content-left">
                                <h3 className="text-purple font-weight-600 solution-heading">
                                    Capacity and Resource <br/>Management
                                </h3>
                                <h6 className="dmsans-regular">
                                    Optimize resource utilization through insights into port & rack availability, device status, user-level visibility and overall network visibility.  This enhances performance, minimizes downtime, and ensures seamless connectivity.
                                </h6>
                                {/* <div className="col-12">
                                    <Link class="btn secondary-btn dmsans-regular text-white solution-btn" to={Products.enterpriseCapacityManagement} target="_blank">
                                        Learn More</Link>
                                </div> */}
                            </div>
                            <div className="col-lg-6 col-sm-6 container-image image-padding-right">
                                <div class="half-background-right bg-purple"></div>
                                <div className=" text-right">
                                    <img className="solution-image" src={Solutions.enterpriseCapacityManagement} />
                                </div>

                            </div>

                        </div>
                    </div>
                    {/* APD STARLING END */}

                    {/* APD VISION START */}
                    <div className="solution-wrapper bg-pink">
                        <div className="row">
                            <div className="col-lg-6 col-sm-6 container-image image-padding-left">
                                <div class="half-background-left bg-red"></div>
                                <div className="">
                                    <img className="solution-image" src={Solutions.enterpriseSecurityAccess} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6 poppins-regular content-alignment-center text-right container-content-right">
                                <h3 className="text-purple font-weight-600 solution-heading">
                                    Security and Access <br/>Control
                                </h3>
                                <h6 className="dmsans-regular">
                                    Real-time visibility into all network changes, ability to physically lock ports, and manage access permissions ensures network security and uninterrupted operations.
                                </h6>
                                {/* <div className="col-12">
                                    <Link class="btn secondary-btn dmsans-regular text-white solution-btn" to={Products.apdDatasheet} target="_blank">
                                        Learn More</Link>
                                </div> */}
                            </div>

                        </div>
                    </div>
                    {/* APD VISION END */}

                    {/* APD STARLING START */}
                    <div className="solution-wrapper bg-pink">
                        <div className="row">
                            <div className="col-lg-6 col-sm-6 poppins-regular content-alignment-center container-content-left">
                                <h3 className="text-purple font-weight-600 solution-heading">
                                Network Diagnostics<br/> and Troubleshooting
                                </h3>
                                <h6 className="dmsans-regular">
                                Keep your network devices running smoothly with proactive health checks. Quickly identify and resolve issues through diagnostic assessments and performance evaluations to ensure optimal functionality. 
                                </h6>
                                {/* <div className="col-12">
                                    <Link class="btn secondary-btn dmsans-regular text-white solution-btn" to={Products.enterpriseCapacityManagement} target="_blank">
                                        Learn More</Link>
                                </div> */}
                            </div>
                            <div className="col-lg-6 col-sm-6 container-image image-padding-right">
                                <div class="half-background-right bg-black"></div>
                                <div className=" text-right">
                                    <img className="solution-image" src={Solutions.networkDiagnostics} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* APD STARLING END */}

                     {/* APD VISION START */}
                     <div className="solution-wrapper bg-pink">
                        <div className="row">
                            <div className="col-lg-6 col-sm-6 container-image image-padding-left">
                                <div class="half-background-left bg-purple"></div>
                                <div className="">
                                    <img className="solution-image" src={Solutions.dataCenterInfrastructure} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6 poppins-regular content-alignment-center text-right container-content-right">
                                <h3 className="text-purple font-weight-600 solution-heading">
                                Data Center and<br/> Infrastructure Management
                                </h3>
                                <h6 className="dmsans-regular">
                                Achieve full visibility of network assets and connections, enabling efficient infrastructure management. Our solution tracks asset lifecycles and ensures thorough network equipment maintenance.
                                </h6>
                                {/* <div className="col-12">
                                    <Link class="btn secondary-btn dmsans-regular text-white solution-btn" to={Products.dataCenterInfrastructure} target="_blank">
                                        Learn More</Link>
                                </div> */}
                            </div>

                        </div>
                    </div>
                    {/* APD VISION END */}

                     {/* APD STARLING START */}
                     <div className="solution-wrapper bg-pink last-solution">
                        <div className="row">
                            <div className="col-lg-6 col-sm-6 poppins-regular content-alignment-center container-content-left">
                                <h3 className="text-purple font-weight-600 solution-heading">
                                AI-Driven Reports &<br/> AI Network Deployments 
                                </h3>
                                <h6 className="dmsans-regular">
                                AI-driven automated reports document data in real-time, enabling comprehensive asset monitoring.  Our solution also supports industry standard AI architectures and the deployment of AI infrastructures.
                                </h6>
                                {/* <div className="col-12">
                                    <Link class="btn secondary-btn dmsans-regular text-white solution-btn" to={Products.enterpriseCapacityManagement} target="_blank">
                                        Learn More</Link>
                                </div> */}
                            </div>
                            <div className="col-lg-6 col-sm-6 container-image image-padding-right">
                                <div class="half-background-right bg-red"></div>
                                <div className=" text-right">
                                    <img className="solution-image" src={Solutions.AIReports} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* APD STARLING END */}
                </div>
            </div>
            <Footer />
        </div>
    );
}