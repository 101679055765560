import "./cross-section-view.scss";

export default function CrossSectionView(props) {

    return (
        <>

            <div class="modal fade modal-lg" id={props.options.modalName} tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title nexa-bold w-100 text-center" dangerouslySetInnerHTML={{ __html: props.options.title }}></h4>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-center">
                            <div className="col-lg-12 passive-patch-panel-section">
                                <div className="row">
                                    <div className="col-lg-3 d-flex align-items-center justify-content-md-center mb-md-0 mb-4 text-md-end text-center">
                                        <h4 className="text-black poppins-regular font-weight-600">100% Passive Patch Panel</h4>
                                    </div>
                                    <div className="col-lg-6">
                                        <img className="w-80" src={props.options.image} />
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center justify-content-md-center mt-lg-0 mt-4  text-md-start text-center">
                                        <h4 className="text-black poppins-regular font-weight-600">with Active Intelligence</h4>
                                    </div>
                                    <div></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}