
import Footer from "../footer/footer";
import Nav from "../nav/nav";
import { Link } from "react-router-dom";
import { constants } from "../../constants";
import "./all-products.scss";
import { Helmet } from 'react-helmet';
import AnimatedText from "../animated-text/animated-text";

export default function AllProducts() {
    const Products = {
        apdDatasheet: '/resources/datasheets/AllPath-Director.pdf',
        apdStarlingPage: '/products/apd-starling',
        apdVisionPage: '/products/apd-vision',
        sensusStarlingPage: '/products/sensus-starling',
        sensusVisionPage: '/products/sensus-vision',
        jSeriesDatasheet: '/resources/datasheets/J-Series.pdf',
        opxDatasheet: '/resources/datasheets/OPX.pdf',
        sensusDatasheet: '/resources/datasheets/Sensus.pdf',
        fiberOpticDatasheet: '/resources/datasheets/Fiber-Optic-Cables.pdf',
        whiteDownloadIcon: '/products/white-download-icon.svg',
    }

    return (
        <div className="bg-white">
            <div>
                <Helmet>
                    <title>Fiber Mountain | All Products</title>
                    <meta name="description" content="Transform your business with our top-quality data center products for mission-critical and enterprise networks." />
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div className="all-products-wrapper bg-white">
                {/* All Solutions Hero Section Start */}
                <Nav />
                <div className="hero">
                    <div className="hero-content d-flex justify-content-center align-items-center flex-column position-relative">
                        <h1 className="poppins-regular hero-sub-heading text-white">
                            Explore Our <span className="poppins-semi-bold">Cutting-Edge</span> Products
                        </h1>
                        <h6 className="text-white dmsans-regular hero-content-sub-heading">Discover data center innovations tailored for enterprise and federal networks </h6>
                    </div>
                </div>
                <div className="container bg-white">
                    {/* APD STARLING START */}
                    <div className="product-wrapper bg-pink">
                        <div className="row">
                            <div className="col-lg-6 col-sm-6 poppins-regular text-white content-alignment-center">
                                <img className="product-logo apd-logo" src="/products/apd-starling-icon.png" />

                                    <h3 className="allpath-heading product-main-heading text-purple font-weight-600">
                                        AllPath<span className="font-weight-400">®</span> Director Starling
                                    </h3>
                                    <h6 className="dmsans-regular apd-main-info-width text-black">
                                        Network Management Software for Enterprise Networks
                                    </h6>
                                    <div className="d-flex text-black product-features">
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="icon" src="/products/all-products/apd-starling-feature-1.png" /></div>
                                            <div className="feature-liner">Accurate Path & Port Visualization </div>
                                        </div>
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="icon" src="/products/all-products/apd-starling-feature-2.png" /></div>
                                            <div className="feature-liner">Instant Alarms & Alerts for Troubleshooting</div>
                                        </div>
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="icon" src="/products/all-products/apd-starling-feature-3.png" /></div>
                                            <div className="feature-liner">Real-time Network Documentation</div>
                                        </div>

                                    </div>
                                    <div className="col-12">
                                    <Link class="btn primary-btn dmsans-regular text-white product-btn btn-learn-more" to={Products.apdStarlingPage}>
                                            Learn more</Link>
                                        <Link class="btn secondary-btn dmsans-regular text-white product-btn" to={Products.apdDatasheet} target="_blank">
                                            Datasheet</Link>
                                    </div>

                            </div>
                            <div className="col-lg-6 col-sm-6 text-right">
                                <img className="apd-slider-1" src="/products/apd-starling.png" />
                                <p className="dmsans-regular product-description">
                                    AllPath® Director Starling is an enterprise network management software that optimizes the management of patch panels & physical connectivity. It automates network documentation, tracks network changes, and reduces errors, providing network-wide visibility while increasing efficiency and lowering operational costs.
                                </p>
                            </div>

                        </div>
                    </div>
                    {/* APD STARLING END */}

                    {/* APD VISION START */}
                    <div className="product-wrapper  bg-pink">
                        <div className="row apd-vision-wrapper">
                            <div className="col-lg-6 col-sm-6 product-image-container">
                                <img className="apd-slider-1" src="/products/apd-vision.png" />
                                <p className="dmsans-regular product-description">
                                    JTIC certified, federal network management software that adds a robust layer of security while optimizing the management of patch panels & physical connectivity. It automates network documentation, track MACs, and reduces errors, providing network-wide visibility.
                                </p>
                            </div>
                            <div className="col-lg-6 col-sm-6 poppins-regular text-white content-alignment-center">
                                <img className="product-logo apd-logo" src="/products/apd-starling-icon.png" />

                                    <h3 className="allpath-heading product-main-heading text-purple font-weight-600">
                                        AllPath<span className="font-weight-400">®</span> Director Vision
                                    </h3>
                                    <h6 className="dmsans-regular apd-main-info-width text-black">
                                        Network Management Software for Mission-Critical Environments
                                    </h6>
                                    <div className="d-flex text-black product-features">
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="icon" src="/products/all-products/apd-vision-feature-1.png" /></div>
                                            <div className="feature-liner">Port & User Level Security</div>
                                        </div>
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="icon" src="/products/all-products/apd-vision-feature-2.png" /></div>
                                            <div className="feature-liner">Encrypted Network Change Logs</div>
                                        </div>
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="icon" src="/products/all-products/apd-vision-feature-3.png" /></div>
                                            <div className="feature-liner">Instant Alarms, Alerts for Troubleshooting</div>
                                        </div>

                                    </div>
                                    <div className="col-12">
                                    <Link class="btn primary-btn dmsans-regular text-white product-btn btn-learn-more" to={Products.apdVisionPage}>
                                            Learn more</Link>
                                        <Link class="btn secondary-btn dmsans-regular text-white product-btn" to={Products.apdDatasheet} target="_blank">
                                            Datasheet</Link>
                                    </div>

                            </div>

                        </div>
                    </div>
                    {/* APD VISION END */}

                    {/* SIMPLPHY SOFTWARE START */}
                    <div className="product-wrapper bg-pink">
                        <div className="row">
                            <div className="col-lg-6 col-sm-6 poppins-regular text-white content-alignment-center">
                                <img className="product-logo simplphy-logo" src="/products/simplphy-icon.png" />

                                    <h3 className="allpath-heading product-main-heading text-purple font-weight-600">
                                        SiMPLphy Software
                                    </h3>
                                    <h6 className="dmsans-regular apd-main-info-width text-black">
                                        Connectivity Management Software
                                    </h6>
                                    <div className="d-flex text-black product-features">
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="icon" src="/products/all-products/simplphy-feature-1.png" /></div>
                                            <div className="feature-liner">Advanced Asset Management Capabilities </div>
                                        </div>
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="icon" src="/products/all-products/simplphy-feature-2.png" /></div>
                                            <div className="feature-liner">Dashboard with Interactive Visual Analytics</div>
                                        </div>
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="icon" src="/products/all-products/simplphy-feature-3.png" /></div>
                                            <div className="feature-liner">Comprehensive Insightful Reports</div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <Link class="btn primary-btn dmsans-regular text-white product-btn" to="https://www.simplphy.com/" target="_blank">
                                            Learn More</Link>
                                    </div>

                            </div>
                            <div className="col-lg-6 col-sm-6 text-right">
                                <img className="apd-slider-1" src="/products/simplphy.png" />
                                <p className="dmsans-regular product-description text-right">
                                    SiMPLphy is an advanced data center connectivity management software that streamlines and enhances the planning and documentation of the physical layer in networking infrastructure. It assists in network expansion, new deployments, and capacity planning.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* SIMPLPHY SOFTWARE END */}

                    {/* SENSUS STARLING START */}
                    <div className="product-wrapper bg-pink">
                        <div className="row sensus-starling-wrapper">
                            <div className="col-lg-6 col-sm-6 product-image-container">
                                <img className="apd-slider-1" src="/products/sensus-starling.png" />
                                <p className="dmsans-regular product-description sensus-starling-description">
                                    Sensus™ Starling is an intelligent patch panel that is designed to secure and automate fiber connectivity management with intelligent detection optimizing data center infrastructure.
                                </p>
                            </div>
                            <div className="col-lg-6 col-sm-6 poppins-regular text-white content-alignment-center">
                                <img className="product-logo sensus-logo" src="/products/sensus-icon.png" />

                                    <h3 className="sensus-heading product-main-heading text-purple font-weight-600">
                                        Sensus™ Starling Patch Panel
                                    </h3>
                                    <h6 className="dmsans-regular apd-main-info-width text-black">
                                        Intelligent Patch Panel for Enterprise Networks
                                    </h6>
                                    <div className="d-flex text-black product-features">
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="icon" src="/products/all-products/sensus-starling-feature-1.png" /></div>
                                            <div className="feature-liner">NFC-enabled cable information</div>
                                        </div>
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="icon" src="/products/all-products/sensus-starling-feature-2.png" /></div>
                                            <div className="feature-liner">Tri-state Interactive LEDs</div>
                                        </div>
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="icon" src="/products/all-products/sensus-starling-feature-3.png" /></div>
                                            <div className="feature-liner">Port Level Diagnostics</div>
                                        </div>

                                    </div>
                                    <div className="col-12">
                                    <Link class="btn primary-btn dmsans-regular text-white product-btn btn-learn-more" to={Products.sensusStarlingPage}>
                                            Learn more</Link>
                                        <Link class="btn secondary-btn dmsans-regular text-white product-btn" to={Products.sensusDatasheet} target="_blank">
                                            Datasheet</Link>
                                    </div>

                            </div>

                        </div>
                    </div>
                    {/* SENSUS STARLING END */}

                    {/* SENSUS VISION START */}
                    <div className="product-wrapper bg-pink">
                        <div className="row">
                            <div className="col-lg-6 col-sm-6 poppins-regular text-white content-alignment-center">
                                <img className="product-logo sensus-logo" src="/products/sensus-icon.png" />

                                    <h3 className="sensus-heading product-main-heading text-purple font-weight-600">
                                        Sensus™ Vision Patch Panel
                                    </h3>
                                    <h6 className="dmsans-regular apd-main-info-width text-black">
                                        Intelligent Patch Panel for Mission-Critical Environments
                                    </h6>
                                    <div className="d-flex text-black product-features">
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="icon" src="/products/all-products/sensus-vision-feature-1.png" /></div>
                                            <div className="feature-liner">Two-step Device Access Verification</div>
                                        </div>
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="icon" src="/products/all-products/sensus-vision-feature-2.png" /></div>
                                            <div className="feature-liner">Network Diagnostics & Health Checks</div>
                                        </div>
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="icon" src="/products/all-products/sensus-vision-feature-3.png" /></div>
                                            <div className="feature-liner">100% Passive Patching Modules</div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                    <Link class="btn primary-btn dmsans-regular text-white product-btn btn-learn-more" to={Products.sensusVisionPage}>
                                            Learn more</Link>
                                        <Link class="btn secondary-btn dmsans-regular text-white product-btn" to={Products.sensusDatasheet} target="_blank">
                                            Datasheet</Link>
                                    </div>

                            </div>
                            <div className="col-lg-6 col-sm-6 product-image-container text-right">
                                <img className="apd-slider-1 sensus-ml-auto" src="/products/sensus-vision.png" />
                                <p className="dmsans-regular product-description text-right sensus-vision-description">
                                    TAA Compliant and JTIC certified Sensus ™ Vision is a managed patch panel with an added layer of security suitable for mission-critical environments. It secures and automates fiber connectivity management with intelligent change detection revolutionizing data center infrastructure.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* SENSUS VISION END */}

                    {/* J-SERIES START */}
                    <div className="product-wrapper bg-pink">
                        <div className="row j-series-wrapper">
                            <div className="col-lg-6 col-sm-6 product-image-container">
                                <img className="j-series-image" src="/products/j-series.png" />
                                <p className="dmsans-regular product-description j-series-description">
                                    J-Series Breakout Panels simplify the management of high-density fiber cables in data centers with their front facing configurations. This allows for easy monitoring, management, and troubleshooting of your network, making it more efficient and reliable.
                                </p>
                            </div>
                            <div className="col-lg-6 col-sm-6 poppins-regular text-white content-alignment-center">
                                {/* <img className="product-logo" src="/products/sensus-icon.png" /> */}

                                    <h3 className="allpath-heading product-main-heading text-purple font-weight-600">
                                        J-Series Breakout Panels
                                    </h3>
                                    <h6 className="dmsans-regular apd-main-info-width text-black">
                                        Modular Breakout Patch Panel
                                    </h6>
                                    <div className="d-flex text-black product-features">
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="icon" src="/products/all-products/apd-starling-feature-1.png" /></div>
                                            <div className="feature-liner">Modular Design: Easy Install, Reconfigure, Upgrade </div>
                                        </div>
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="icon" src="/products/all-products/apd-starling-feature-2.png" /></div>
                                            <div className="feature-liner">Network Equipment Protection</div>
                                        </div>
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="icon" src="/products/all-products/apd-starling-feature-3.png" /></div>
                                            <div className="feature-liner">Wide Range Bandwidth Support</div>
                                        </div>

                                    </div>
                                    <div className="col-12">
                                        <Link class="btn secondary-btn dmsans-regular text-white product-btn" to={Products.jSeriesDatasheet} target="_blank">
                                            Datasheet</Link>
                                    </div>

                            </div>

                        </div>
                    </div>
                    {/* J-SERIES END */}

                    {/* <div className="product-wrapper bg-pink">
                        <div className="row">
                            <div className="col-lg-6 col-sm-6 poppins-regular text-white content-alignment-center">
                                <img className="product-logo" src="/products/opx-icon.png" />

                                    <h2 className="allpath-heading product-main-heading text-purple">
                                        OPX® - Optical Path Exchange
                                    </h2>
                                    <h5 className="dmsans-regular apd-main-info-width text-black">
                                        Layer One Switches
                                    </h5>
                                    <div className="d-flex text-black product-features">
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="" src="/products/all-products/apd-starling-feature-1.png" /></div>
                                            <div className="feature-liner">Ultra-low Port-to-Port Latency</div>
                                        </div>
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="" src="/products/all-products/apd-starling-feature-2.png" /></div>
                                            <div className="feature-liner">Multimode Cable Support</div>
                                        </div>
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="" src="/products/all-products/apd-starling-feature-3.png" /></div>
                                            <div className="feature-liner">Tri-state Interactive LEDs</div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <Link class="btn secondary-btn dmsans-regular text-white product-btn" to={Products.apdDatasheet} target="_blank">
                                            Learn More</Link>
                                    </div>

                            </div>
                            <div className="col-lg-6 col-sm-6 product-image-container text-right">
                                <img className="apd-slider-1" src="/products/opx.png" />
                                <h5 className="dmsans-regular product-description text-right opx-description">
                                    OPX® switches and cross-connects are scalable to support any network protocol. They automate patching, enhance network visibility, and minimize signal loss, ensuring secure and dynamic network management capabilities.
                                </h5>
                            </div>
                        </div>
                    </div> */}

                    {/* FIBER OPTIC CABLES START */}
                    <div className="product-wrapper last-product bg-pink">
                        <div className="row cables-wrapper">

                            <div className="col-lg-6 col-sm-6 poppins-regular text-white content-alignment-center">
                                <img className="product-logo cables-logo" src="/products/cables-icon.png" />

                                    <h3 className="allpath-heading product-main-heading text-purple font-weight-600">
                                    Fiber Optic Cables
                                    </h3>
                                    <h6 className="dmsans-regular apd-main-info-width text-black">
                                    Premium Fiber Optic Cables
                                    </h6>
                                    <div className="d-flex text-black product-features">
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="icon" src="/products/all-products/apd-starling-feature-1.png" /></div>
                                            <div className="feature-liner">Multi-strands Cables </div>
                                        </div>
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="icon" src="/products/all-products/apd-starling-feature-2.png" /></div>
                                            <div className="feature-liner">Support High Speed Applications</div>
                                        </div>
                                        <div className="bg-white feature-wrapper">
                                            <div><img className="icon" src="/products/all-products/apd-starling-feature-3.png" /></div>
                                            <div className="feature-liner">NFC-enabled Cables </div>
                                        </div>

                                    </div>
                                    <div className="col-12">
                                        <Link class="btn secondary-btn dmsans-regular text-white product-btn" to={Products.fiberOpticDatasheet} target="_blank">
                                            Datasheet</Link>
                                    </div>
                            </div>
                            <div className="col-lg-6 col-sm-6 product-image-container">
                                <img className="apd-slider-1" src="/products/fiber-optic-cables.png" />
                                <p className="dmsans-regular product-description cables-description text-right">
                                Fiber Mountain provides high-density multi-strands cables and Intelligent Connection Identification (ICID®) clips, ensuring efficient and reliable data transmission for comprehensive data center cabling needs. 
                                </p>
                            </div>

                        </div>
                    </div>
                    {/* FIBER OPTIC CABLES END */}
                </div>
            </div>
            <Footer />
        </div>
    );
}