export const constants =
{
    images: {
        solutionDiagramLines: {
            dataCenterLine: '/icons/datacenter-line.svg',
            securityLine: '/icons/security-line.svg',
            breakoutLine: '/icons/breakout-line.svg',
            capacityLine: '/icons/capacity-line.svg',
            documentationLine: '/icons/documentation-line.svg',
            diagnosticsLine: '/icons/diagnostics-line.svg',
        },
        solutionDiagramIcons: {
            dataCenterIcon: '/icons/icon-datacenter.svg',
            securityIcon: '/icons/icon-security.svg',
            breakoutIcon: '/icons/icon-universal-breakout.svg',
            capacityIcon: '/icons/icon-capacity.svg',
            documentationIcon: '/icons/icon-documentation.svg',
            diagnosticsIcon: '/icons/icon-diagnostics.svg',
        },
        solutionDiagramCircles: '/solutions/dotted-circle.svg',

        glcBenefitBackground: {
            verticalDots: '/backgrounds/benefits-vertical-dots.png',
            outlinedLogo: '/backgrounds/bg-logo-outline.png',
        },
        glcBenefitsIcons: {
            dataCenterOutageIcon: '/icons/icon1.svg',
            dataCenterServiceOutageIcon: '/icons/icon2.svg',
            surveyCostsIcon: '/icons/icon3.svg',
            dataSecurityIcon: '/icons/icon4.svg',
        },
        logos: {
            FMLogo: '/logos/FM-logo.svg',
            FMFooterLogo: '/logos/GLC-footer-logo.png'

        },
        glcRequestDemoBg: {
            topLeft: '/backgrounds/request-demo-top-bg.png',
            bottomRight: '/backgrounds/request-demo-bottom-right.png'
        }
    },

links: {
    socialMediaLinks: [
        {
            url: 'https://www.facebook.com/profile.php?id=100085866826868',
            icon: '/icons/social-icons/facebook.svg',
        },

        {
            url: 'https://www.linkedin.com/company/green-lambda-corporation',
            icon: '/icons/social-icons/linked-in.svg',

        },
        {
            url: 'https://www.youtube.com/channel/UCpiIIq-ywzbbFM0c8qkqJMg',
            icon: '/icons/social-icons/youtube.svg',
        },
        // {
        //     url: 'https://www.youtube.com/channel/UCpiIIq-ywzbbFM0c8qkqJMg',
        //     icon: '/icons/social-icons/instagram.svg',
        // }

    ]
}
}


