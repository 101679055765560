import Footer from "../footer/footer";
import Nav from "../nav/nav";
import MoreProducts from "../more-products/more-products";
import "./apd-starling.scss";
import { Link } from "react-router-dom";
import DraggableSlider from "./draggable-slider";
import { Helmet } from 'react-helmet';

export default function APD() {

    const APD = {
        apdLogo: '/products/apd-assets/apd-logo-white.svg',
        apdCallouts: '/products/apd-assets/apd-showcase.png',
        datasheet: '/resources/datasheets/AllPath-Director.pdf',
        apdFeatureNetworkGlance: '/products/apd-assets/apd-feature-starling-network-at-a-glance.png',
        apdFeatureDocumentNetwork: '/products/apd-assets/apd-feature-starling-document-network-in-real-time.png',
        apdFeatureEasyDeployments: '/products/apd-assets/apd-feature-starling-manage-deployments-across-the-globe.png',
        apdFeatureInstantAlarms: '/products/apd-assets/apd-feature-starling-instant-alerts-for-fast-troubleshooting.png',
        apdFeatureDiagnoseNetworkIssues: '/products/apd-assets/apd-feature-starling-diagnose-network-issues-quickly-easily.png',
        apdFeatureLocateNetworkPorts: '/products/apd-assets/apd-feature-starling-locate-network-ports.png',
        usecases: [
            {
                title: "Intelligent Network Capacity Management"
            },
            {
                title: "Proactive Alarm Management"
            },
            {
                title: "Topology Visualization and Management"
            },
            {
                title: "Dynamic Path and Port Management"
            },
            {
                title: "Advanced Port State Management"
            },
            {
                title: "Diagnosing network devices for troubleshooting"
            },
            {
                title: "Documenting network changes in real time – Automated reports "
            },
            {
                title: "Automated Compliance and Auditing"
            },
            {
                title: "Zero-touch Provisioning"
            },
            {
                title: "End to end Network deployment with Sensus™"
            }

        ],

        products: [
            {
                image: '/products/more-products/sensus-starling.png',
                title: 'Sensus™ Starling Patch Panel',
                oneLiner: 'Intelligent Patch Panel for Enterprise Networks',
                information: 'Sensus™ Starling is an intelligent patch panel that is designed to secure and automate fiber connectivity management with intelligent detection optimizing data center infrastructure.',
                url: '/products/sensus-starling',
            },
            {
                image: '/products/more-products/sensus-vision.png',
                title: 'Sensus™ Vision Patch Panel',
                oneLiner: 'Intelligent Patch Panel for Mission-Critical Environments',
                information: 'TAA Compliant and JTIC certified Sensus ™ Vision is a managed patch panel with an added layer of security suitable for mission-critical environments. It secures and automates fiber connectivity management with intelligent change detection revolutionizing data center infrastructure.',
                url: '/products/sensus-vision',
            },
            {
                image: '/products/more-products/apd-vision.png',
                title: 'AllPath® Director Vision ',
                oneLiner: 'Network Management Software for Mission-Critical Environments',
                information: 'JTIC certified, federal network management software that adds a robust layer of security while optimizing the management of patch panels & physical connectivity. It automates network documentation, tracks MACs, and reduces errors, providing network-wide visibility.',
                url: '/products/apd-vision',
            },

        ],
        cdnControlSider1: [
            {
                src: '/products/apd-assets/slide1.png',
            },
            {
                src: '/products/apd-assets/slide2.png',
            },
            {
                src: '/products/apd-assets/slide3.png',
            },
        ],
        cdnControlSider2: [
            {
                src: '/products/apd-assets/slide4.png',
            },
            {
                src: '/products/apd-assets/slide5.png',
            },
            {
                src: '/products/apd-assets/slide6.png',
            },
        ],
    }

    return (
        <>
            <div>
                <Helmet>
                    <title>Fiber Mountain | AllPath® Director Vision</title>
                    <meta name="description" content="Secure, optimize, and automate your network with our JTIC certified software, specifically designed to meet federal network management needs." />
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div className="apd-wrapper">
                {/* Nav Section Start */}
                <Nav />
                {/* Nav Section End */}

                {/* Hero Section Start */}
                <div className="hero">
                    <div className="hero-content hero-bg bg-blue d-flex align-items-center flex-column position-relative">
                        <div className="hero-heading-bg"><h1 className="poppins-semi-bold text-white text-center hero-heading">AllPath® Director Starling</h1></div>
                        <h6 className="dmsans-regular text-white hero-subheading text-center">All-in-One Software for Real-Time Network Visibility and Control </h6>
                    </div>
                </div>
                {/* Hero Section End */}


                <div className="bg-white">
                    <div className="">
                        <div className="container apd-diagram-container">
                            {/* Product Diagram Section Start */}
                            <div className="row px-lg-4 px-sm-0">
                                <div className="col-lg-12 mx-auto">
                                    <div className="row mb-3">
                                        <div className="col-12 text-center position-relative">
                                            <img className="w-100 apd-banner-image" src={APD.apdCallouts} />
                                        </div>
                                    </div>
                                    <div className="text-center text-white apd-diagram-heading">
                                <h4 className="dmsans-regular text-black">AllPath® Director Starling is an enterprise network management software that optimizes patch panel management, automates documentation, tracks changes, and enhances visibility and efficiency.</h4>
                            </div>


                                    <div className="col-12 text-center mt-sm-0 diagram-btn-container">
                                        <Link to={APD.datasheet} target="_blank" className="btn primary-btn dmsans-regular text-white mt-2">DATASHEET</Link>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Product Diagram Section End */}

                        {/* APD Slider Start */}
                        <div className="col-lg-12 container apd-slider-section text-center">
                            <h2 className="poppins-regular text-black font-weight-400">Discover the Future of Connectivity with APD</h2>
                        </div>
                        <DraggableSlider images={APD.cdnControlSider1} addedClass='slider1' />
                        <DraggableSlider images={APD.cdnControlSider2} addedClass='slider2' />
                        {/* APD SDN Control Slider End */}

                        {/* APD Features Start */}
                        <div className="col-lg-12 container apd-features-section">
                            <div className="text-center">
                                <h2 className="poppins-regular text-black font-weight-400 text-center">Feature Highlights</h2>
                            </div>
                            <div className="row text-black feature-element">
                                <div className="col-lg-6 col-sm-6 m-auto">
                                    <h3 className="poppins-semi-bold w-80">View Your <span className="text-primary-red">Whole Network</span> at a Glance</h3>
                                    <h6 className="dmsans-regular">
                                    Get a centralized view of your network, with real-time insights into network changes, system status, and connections, enhancing overall monitoring and management efficiency.
                                    </h6>
                                </div>
                                <div className="col-lg-6 col-sm-6 text-center">
                                    <img className="my-auto w-100" src={APD.apdFeatureNetworkGlance} />
                                </div>
                            </div>
                            <div className="row text-black feature-element element-column-reverse">
                                <div className="col-lg-6 col-sm-6 text-center">
                                    <img className="my-auto w-100" src={APD.apdFeatureDocumentNetwork} />
                                </div>
                                <div className="col-lg-6 col-sm-6 m-auto text-right">
                                    <h3 className="poppins-semi-bold">Document Your Network in <span className="text-primary-red">Real-time</span></h3>
                                    <h6 className="dmsans-regular margin-left-auto">
                                    Automatically track your network and record any moves, additions and changes. Eliminating the need for manual documentation, ensuring accurate and up-to-date information for efficient network management.
                                    </h6>
                                </div>
                            </div>
                            <div className="row text-black feature-element">
                                <div className="col-lg-6 col-sm-6 m-auto">
                                    <h3 className="poppins-semi-bold"><span className="text-primary-red">Easily Manage Your Deployments </span>Across the Globe</h3>
                                    <h6 className="dmsans-regular">
                                    Efficiently organize, manage and view all deployments no matter where they are in the world. Easy-to-navigate world map view lets you handle deployments with just a few clicks.
                                    </h6>
                                </div>
                                <div className="col-lg-6 col-sm-6 text-center">
                                    <img className="my-auto w-100" src={APD.apdFeatureEasyDeployments} />
                                </div>
                            </div>
                            <div className="row text-black feature-element element-column-reverse">
                                <div className="col-lg-6 col-sm-6 text-center d-flex">
                                    <img className="my-auto w-100" src={APD.apdFeatureInstantAlarms} />
                                </div>
                                <div className="col-lg-6 col-sm-6 m-auto text-right">
                                    <h3 className="poppins-semi-bold">Get Instant Alerts for <span className="text-primary-red">Fast Troubleshooting </span></h3>
                                    <h6 className="dmsans-regular margin-left-auto">
                                    Real-time alarms and alerts detect unauthorized changes, allowing for rapid responses to prevent outages and security issues.
                                    </h6>
                                </div>
                            </div>
                            <div className="row text-black feature-element">
                                <div className="col-lg-6 col-sm-6 m-auto">
                                    <h3 className="poppins-semi-bold"><span className="text-primary-red">Diagnose Network Issues </span> Quickly and Easily</h3>
                                    <h6 className="dmsans-regular">
                                    Network diagnostic assessments streamline troubleshooting and enable efficient resolution of network issues.
                                    </h6>
                                </div>
                                <div className="col-lg-6 col-sm-6 text-center">
                                    <img className="my-auto w-100" src={APD.apdFeatureDiagnoseNetworkIssues} />
                                </div>
                            </div>
                            <div className="row text-black feature-element element-column-reverse">
                                <div className="col-lg-6 col-sm-6 text-center">
                                    <img className="my-auto w-100" src={APD.apdFeatureLocateNetworkPorts} />
                                </div>
                                <div className="col-lg-6 col-sm-6 m-auto text-right">
                                    <h3 className="poppins-semi-bold">Locate <span className="text-primary-red">Network Ports and Paths</span> to Know What's Where</h3>
                                    <h6 className="dmsans-regular margin-left-auto">
                                    Port & Path Finder in AllPath® Director simplifies network management by locating available ports and visualizing existing network paths enhancing overall connectivity efficiency.
                                    </h6>
                                </div>
                            </div>
                        </div>
                        {/* APD Features End */}


                        {/* APD Usecase Start */}
                        <div className="col-lg-12 container apd-usecase-section text-center">
                            <h2 className="poppins-regular text-black font-weight-400">Real-World Applications of AllPath® Director Starling </h2>
                            <div className="row">
                                {APD.usecases.map((value, index) => {
                                    return (
                                        <div className="col-md-3">
                                            <div className="bg-purple use-case-wrapper">
                                                <h6 className="poppins-regular text-white font-weight-600" dangerouslySetInnerHTML={{ __html: value.title }}></h6>
                                            </div>

                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                        {/* APD Usecase end */}

                    </div>
                      {/* More Products Section Start */}
                      <MoreProducts products={APD.products} />
                        {/* More Product Section End */}
                </div>
                <Footer />
            </div >
        </>
    );
}