import "./video-modal.scss";
import React, { useEffect, useRef } from 'react';

export default function VideoModal(props) {
    const modalRef = useRef(null);
    const iframeRef = useRef(null);

    useEffect(() => {
        const modalElement = modalRef.current;
        const iframeElement = iframeRef.current;

        const hideModalHandler = () => {
            // Reset the source of the iframe to stop the video
            iframeElement.src = '';
        };

        const showModalHandler = () => {
            // Reset the source of the iframe to show the video
            iframeElement.src = `https://www.youtube.com/embed/${props.options.videoLink}?enablejsapi=1&rel=0`;
        };

        if (modalElement) {
            modalElement.addEventListener('hide.bs.modal', hideModalHandler);
            modalElement.addEventListener('show.bs.modal', showModalHandler);
        }

        return () => {
            if (modalElement) {
                modalElement.removeEventListener('hide.bs.modal', hideModalHandler);
                modalElement.addEventListener('show.bs.modal', showModalHandler);
            }
        };
    },);
    
    return (
        <>
            <div class="modal fade modal-xl" tabindex="-1" aria-hidden="true" id={props.options.modalName} ref={modalRef}>
                <div class="modal-dialog modal-dialog-centered video-modal-dialogue">
                    <div class="modal-content video-player">
                        <iframe
                            className="mx-auto"
                            ref={iframeRef}
                            width="1280"
                            height="720"
                            src={`https://www.youtube.com/embed/${props.options.videoLink}?enablejsapi=1&rel=0`}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            </div>
        </>
    )
}