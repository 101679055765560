import "./nav.scss";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { constants } from "../../constants";
import { Spiral as Hamburger } from 'hamburger-react';
import { CSSTransition } from 'react-transition-group';


export default function Nav() {
  const nav = {
    joinUs: 'https://greenlambda.bamboohr.com/careers',
    menuItems: [
      {
        title: 'Products',
        url: '/all-products',
      },
      {
        title: 'Resources',
        url: '/fm-resources',
      },
      // {
      //   title: 'Solutions',
      //   url: '#',
      // },
      {
        title: 'About Us',
        url: '/about-us',
      },
      {
        title: 'Events',
        url: '/events',
      },
      {
        title: 'Contact Us',
        url: '/contact-us',
      },
    ],
    productsLinks: [
      {
        title: 'All Products',
        url: '/all-products',

      },
      {
        title: 'AllPath® Director',
        url: '/products/apd',
      },
      {
        title: 'Fiber Optic Cables',
        url: '/products/fiber-optic-cables',
      },
      {
        title: 'J-Series',
        url: '/products/j-series',
      },
      {
        title: 'OPX® (Optical Path Exchange)',
        url: '/products/opx',
      },
      {
        title: 'Sensus™',
        url: '/products/sensus',
      }
    ],
    companyLinks: [
      {
        title: 'About Us',
        url: '/about-us',
        openInNewTab: false
      },
      {
        title: 'Life at Green Lambda',
        url: '/glc-team',
        openInNewTab: false
      },
      {
        title: 'Contact Us',
        url: '/contact-us',
        openInNewTab: false
      }
    ],
    solutionLinks: [
      {
        title: 'Our Solutions',
        url: '/all-solutions',
      },
      {
        title: 'Automated Documentation',
        url: '/solutions/automated-documentation',
      },
      {
        title: 'Capacity Management',
        url: '/solutions/capacity-management',
      },
      {
        title: 'Data Center Colocation',
        url: '/solutions/datacenter-colocation',
      },
      {
        title: 'Layer One Diagnostics',
        url: '/solutions/layer-one-diagnostics',
      },
      {
        title: 'Physical Layer Security',
        url: '/solutions/physical-layer-security',
      },
      {
        title: 'Universal Breakout',
        url: '/solutions/universal-breakout',
      },
    ],
    resourcesLinks: [
      {
        title: 'All Resources',
        url: '/resources',
        openInNewTab: false
      },
      {
        title: 'Documentation Portal',
        url: 'https://docs.greenlambda.com/',
        openInNewTab: true
      },
    ]
  }
  const [mobileSelectedItem, setMobileSelectedItem] = useState(0);
  const [selectedItem, setSelectedItem] = useState(0);
  function displaySubMenu(index) {
    //e.preventDefault();
    setSelectedItem(index);
  }
  const handleHamburgerClick = () => {
    setIsMegaMenuOpen(!isMegaMenuOpen);
  };
  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  useEffect(() => {
  }, [isMegaMenuOpen, selectedItem]);

  function menuToggler(index, title) {
    if (title === selectedMenuItem) {
      setIsMegaMenuOpen(false)
      setSelectedMenuItem(null)
      // document.body.style.overflow = 'unset';
    }
    else {
      setSelectedMenuItem(title);
      setIsMegaMenuOpen(true);
      // document.body.style.overflow = 'hidden';
    }
  }
  return (
    <>
      <div className="nav-wrapper">
        <nav className={`navbar navbar-expand-lg bg-white ${isMegaMenuOpen ? '' : 'navbar-shadow'}`}>
          <div className="container-lg top-nav-wrapper">
            <div className="col-12 d-flex align-items-center">
              <div className="col-lg-2 col-6">
                <a className="navbar-brand" href="/">
                  <img
                    src={constants.images.logos.FMLogo}
                    target="blank"
                    alt="logo"
                    className="fm-logo" />
                </a>
              </div>
              <div className="col-10 d-lg-flex d-none justify-content-end">
                <ul className="navbar-nav text-black nav-list">
                  {nav.menuItems.map((value, index) => {
                    return (
                      <li className='nav-item top-nav-option cursor-pointer text-primary-green text-black' key={index}>
                        <a href={value.url} className="text-black nav-link-style">
                          {value.title}
                        </a>
                      </li>
                    )

                  })}
                </ul>
              </div>
                <div className="col-lg-2 col-6 d-flex justify-content-end">
                <div className="hamburger-container">
                  <Hamburger color="#270722" size={32} toggled={isMegaMenuOpen} toggle={handleHamburgerClick} />
                </div>
              </div>
            </div>
          </div>
        </nav>
        {/* <CSSTransition
          in={isMegaMenuOpen}
          timeout={300}
          classNames="menu-transition"
          unmountOnExit>
          <>
            <div className="container-fluid bg-blue">
              <div className={`container mega-menu nav-container mega-menu-desktop menu-transition ${isMegaMenuOpen ? 'show' : ''}`}>
                <div className="row">
                  <div className="col-lg-4 col-xl-4">
                    <div className="nav-image">
                      <img className="nav-img mw-100" src={nav.menuItems[selectedItem].image} />
                    </div>

                  </div>
                  <div className="offset-lg-2 col-lg-6 col-xl-6">
                    <div className="row text-center links-row">
                      <div className="col-lg-6 padding-mega-links">
                        <ul className="list-unstyled nav-main-list">
                          {nav.menuItems.map((value, index) => {
                            return (
                              <li className="nav-item" key={value.id}>
                                <span key={index}
                                  className={'nav-link text-small pb-0 nexa-bold ' +
                                    (index === selectedItem ? 'text-primary-green' : 'text-white')
                                  } onClick={() => { setSelectedItem(index) }}>
                                  {value.title}
                                </span>
                              </li>
                            )
                          })}
                        </ul>
                        <div className="d-flex justify-content-center flex-column align-items-left nav-btn-width">
                        <a
                          href="/contact-us"
                          className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color mt-2 mb-3">
                          GET IN TOUCH
                        </a>
                        <a
                          href="https://greenlambda.bamboohr.com/careers" target="_blank"
                          className="btn secondary-btn dmsans-regular text-green border-secondary-color mt-2 mb-3">
                          JOIN US
                        </a>
                        </div>
                      </div>
                      {selectedItem >= 0 && (
                        <div className="col-lg-6 my-auto">
                          <ul className="list-unstyled nav-main-list">
                            {nav.menuItems[selectedItem].submenu.map((value, index) => {
                              return (
                                <li className="nav-item page-item">
                                  <Link to={value.url} key={index} target={value.openInNewTab ? "_blank" : "_self"} className="nav-link text-small pb-0 text-white nexa-light page-link" dangerouslySetInnerHTML={{ __html: value.title }}></Link>
                                  <img className="mt-0" src="/nav/line.png" />
                                </li>
                              );
                            })}
                          </ul>
                        </div>)
                      }
                    </div>
                  </div>
                  <div className="nav-bottom-gradient"></div>

                </div>
                <div className="d-flex justify-content-center flex-column social-links">
                  <div id="social" className="social-links">
                    <span className="text-white follow-text nexa-light mx-2">Follow us at: </span>
                    {constants.links.socialMediaLinks.map((value, key) => {
                      return (<a
                        className="mx-2" key={key}
                        target="_blank"
                        href={value.url} >
                        <img src={value.icon} />
                      </a>
                      )
                    })}
                  </div>
                </div>
              </div>
 */}
   {isMegaMenuOpen && (
              <div className="mega-menu-mobile bg-pink">
                <div class="accordion" id="accordionFlushExample">
                  <div className="accordion-item">
                      <div className="accordion-body text-white dmsans-regular">
                        <ul className="list-unstyled nav-main-list mb-0">
                          {nav.menuItems.map((value, index) => {
                            return (
                              <li className="nav-item page-item">
                                <Link to={value.url} key={index} className="nav-link text-small pb-0 text-black poppins-regular page-link" dangerouslySetInnerHTML={{ __html: value.title }} >
                                </Link>
                                <img className="mt-0" src="/nav/line.png" />
                              </li>
                            );
                          })}
                        </ul>
                    </div>
                  </div>
                </div>
              </div>
 )}
              {/*
            </div>
          </>
        </CSSTransition> */}
      </div>
    </>
  );
}
