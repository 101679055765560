import "./more-products.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import AnimatedText from "../animated-text/animated-text";

export default function MoreProducts({ products }) {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1
    }
    return (
        <>
            <div className="more-products-container bg-pink">
                    <h3 className="text-black more-products-heading text-center poppins-regular">Explore More Products</h3>
                <Slider {...settings}>
                    {products.map((value, index) => {
                        return (
                            <div className="slide-item bg-white">
                                <div className="row">
                                    <div className="col-lg-6 col-sm-12 my-auto">
                                        <h4 className="poppins-regular text-black font-weight-600"><span dangerouslySetInnerHTML={{ __html: value.title }}></span>
                                        </h4>
                                        <h6 className="dmsans-regular text-primary-red font-weight-700 one-liner"><span dangerouslySetInnerHTML={{ __html: value.oneLiner }}></span>
                                        </h6>

                                            <p className="text-black" dangerouslySetInnerHTML={{ __html: value.information }}>
                                            </p>
                                            <Link to={value.url} className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color mt-2">LEARN MORE</Link>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 my-auto position-relative">
                                        <img className="mx-auto" src={value.image}></img>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            </div>
        </>
    );
}