export default function DraggableSlider(props) {

        return (
            <>
                <div className={'slider ' + props.addedClass}>
                    <div className="slide-track">
                        {props.images.map((val, index) =>
                            <div
                                key={index}
                                className={index % 2 == 1 ? "slide" : "slide"}>
                                <img src={val.src} />
                            </div>
                        )}
                        {props.images.map((val, index) =>
                            <div
                                key={index * 2}
                                className={index % 2 == 1 ? "slide" : "slide"}>
                                <img src={val.src} />
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }