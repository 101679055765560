import Footer from "../footer/footer";
import Nav from "../nav/nav";
import MoreProducts from "../more-products/more-products";
import "./apd-vision.scss";
import { Link } from "react-router-dom";
import DraggableSlider from "./draggable-slider";
import { Helmet } from 'react-helmet';

export default function APD() {

    const APD = {
        apdLogo: '/products/apd-assets/apd-logo-white.svg',
        apdCallouts: '/products/apd-assets/apd-showcase.png',
        datasheet: '/resources/datasheets/AllPath-Director.pdf',
        apdFeatureAPLandJTIC: '/products/apd-assets/apd-feature-APL-JTIC-certified.png',
        apdFeatureTwoStepVerification: '/products/apd-assets/apd-feature-two-step-verification-security.png',
        apdFeatureNetworkLogsEncryption: '/products/apd-assets/apd-feature-safeguard-network-logs-encryption.png',
        apdFeatureTroubleshootFaster: '/products/apd-assets/apd-feature-troubleshoot-faster-with-instant-alarms-alerts.png',
        apdFeatureDocumentNetwork: '/products/apd-assets/apd-feature-document-network-in-real-time.png',
        apdFeatureComprehensiveInsights: '/products/apd-assets/apd-feature-comprehensive-insights.png',
        apdFeatureHealthCheck: '/products/apd-assets/apd-feature-check-health-of-network-devices.png',
        apdFeatureIdentifyPathsandPorts: '/products/apd-assets/apd-feature-Identify-network-ports-and-paths.png',


        usecases: [
            {
                title: "Secured</br> Deployments"
            },
            {
                title: "Proactive Alarm Management"
            },
            {
                title: "Topology Visualization and Management"
            },
            {
                title: "Dynamic Path and Port Management"
            },
            {
                title: "Advanced Port State Management"
            },
            {
                title: "Diagnosing network devices for troubleshooting"
            },
            {
                title: "Documenting network changes in real time – Automated reports "
            },
            {
                title: "Automated Compliance and Auditing"
            },
            {
                title: "Intelligent Network Capacity Management"
            },
            {
                title: "End to end Network deployment with Sensus™"
            }

        ],

        products: [
            {
                image: '/products/more-products/sensus-vision.png',
                title: 'Sensus™ Vision Patch Panel',
                oneLiner: 'Intelligent Patch Panel for Mission-Critical Environments',
                information: 'TAA Compliant and JTIC certified Sensus ™ Vision is a managed patch panel with an added layer of security suitable for mission-critical environments. It secures and automates fiber connectivity management with intelligent change detection revolutionizing data center infrastructure.',
                url: '/products/sensus-vision',
            },
            {
                image: '/products/more-products/sensus-starling.png',
                title: 'Sensus™ Starling Patch Panel',
                oneLiner: 'Intelligent Patch Panel for Enterprise Networks',
                information: 'Sensus™ Starling is an intelligent patch panel that is designed to secure and automate fiber connectivity management with intelligent detection optimizing data center infrastructure.',
                url: '/products/sensus-starling',
            },

            {
                image: '/products/more-products/apd-starling.png',
                title: 'AllPath® Director Starling',
                oneLiner: 'Network Management Software for Mission-Critical Environments',
                information: 'AllPath® Director Starling is an enterprise network management software that optimizes the management of patch panels & physical connectivity. It automates network documentation, tracks network changes, and reduces errors, providing network-wide visibility while increasing efficiency and lowering operational costs.',
                url: '/products/apd-starling',
            },

        ],
        cdnControlSider1: [
            {
                src: '/products/apd-assets/slide1.png',
            },
            {
                src: '/products/apd-assets/slide2.png',
            },
            {
                src: '/products/apd-assets/slide3.png',
            },
        ],
        cdnControlSider2: [
            {
                src: '/products/apd-assets/slide4.png',
            },
            {
                src: '/products/apd-assets/slide5.png',
            },
            {
                src: '/products/apd-assets/slide6.png',
            },
        ],
    }

    return (
        <>
            <div>
                <Helmet>
                    <title>Fiber Mountain | AllPath® Director Vision</title>
                    <meta name="description" content="Secure, optimize, and automate your network with our JTIC certified software, specifically designed to meet federal network management needs." />
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div className="apd-wrapper">
                {/* Nav Section Start */}
                <Nav />
                {/* Nav Section End */}

                {/* Hero Section Start */}
                <div className="hero">
                    <div className="hero-content hero-bg bg-blue d-flex align-items-center flex-column position-relative">
                        <div className="hero-heading-bg"><h1 className="poppins-semi-bold text-white text-center hero-heading">AllPath® Director Vision</h1></div>
                        <h6 className="dmsans-regular text-white hero-subheading text-center">Secure and Efficient Network Management for Mission-Critical Environments </h6>
                    </div>
                </div>
                {/* Hero Section End */}


                <div className="bg-white">
                    <div className="">
                        <div className="container apd-diagram-container">
                            {/* Product Diagram Section Start */}

                            <div className="row px-4 px-lg-0">
                                <div className="col-lg-12 mx-auto">
                                    <div className="row mb-3">
                                        <div className="col-12 text-center position-relative">
                                            <img className="w-100 apd-banner-image" src={APD.apdCallouts} />
                                        </div>
                                    </div>
                                    <div className="text-center text-white apd-diagram-heading">
                                        <h4 className="dmsans-regular text-black">AllPath® Director Vision is a network management software, designed to meet federal needs. It adds a robust layer of security and optimizes patch panel management, automates documentation, tracks changes, and enhances visibility and resiliency.</h4>
                                    </div>

                                    <div className="col-12 text-center mt-sm-0 diagram-btn-container">
                                        <Link to={APD.datasheet} target="_blank" className="btn primary-btn dmsans-regular text-white mt-2">DATASHEET</Link>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Product Diagram Section End */}

                        {/* APD Slider Start */}
                        <div className="col-lg-12 container apd-slider-section text-center">
                            <h2 className="poppins-regular text-black font-weight-400">Discover the Future of Connectivity with APD</h2>
                        </div>
                        <DraggableSlider images={APD.cdnControlSider1} addedClass='slider1' />
                        <DraggableSlider images={APD.cdnControlSider2} addedClass='slider2' />
                        {/* APD SDN Control Slider End */}

                        {/* APD Features Start */}
                        <div className="col-lg-12 container apd-features-section">
                            <div className="text-center">
                                <h2 className="poppins-regular text-black font-weight-400 text-center">Feature Highlights</h2>
                            </div>
                            <div className="row text-black feature-element">
                                <div className="col-lg-6 col-sm-6 m-auto">
                                    <h3 className="poppins-semi-bold w-80"><span className="text-primary-red">APL and JTIC </span>Certified Solution</h3>
                                    <h6 className="dmsans-regular">
                                        APL and JTIC certification guarantee top-tier security and compliance for federal networks highlighting our commitment to delivering reliable, high-standard solutions that meet the most stringent government and industry requirements.
                                    </h6>
                                </div>
                                <div className="col-lg-6 col-sm-6 text-center">
                                    <img className="my-auto w-100" src={APD.apdFeatureAPLandJTIC} />
                                </div>
                            </div>
                            <div className="row text-black feature-element element-column-reverse">
                                <div className="col-lg-6 col-sm-6 text-center">
                                    <img className="my-auto w-100" src={APD.apdFeatureTwoStepVerification} />
                                </div>
                                <div className="col-lg-6 col-sm-6 m-auto text-right">
                                    <h3 className="poppins-semi-bold">Enhance Network Security via <span className="text-primary-red">Two-Step Verification</span></h3>
                                    <h6 className="dmsans-regular margin-left-auto">
                                        AllPath® Director Vision enhances security through two-step verification, guaranteeing that only authorized personnel can control and access your sensitive network infrastructure.
                                    </h6>
                                </div>
                            </div>
                            <div className="row text-black feature-element">
                                <div className="col-lg-6 col-sm-6 m-auto">
                                    <h3 className="poppins-semi-bold">Safeguard Network Logs with <span className="text-primary-red">Encryption</span></h3>
                                    <h6 className="dmsans-regular">
                                        Protect sensitive data with encrypted network logs, ensuring secure transmission and storage to prevent unauthorized access and tampering.
                                    </h6>
                                </div>
                                <div className="col-lg-6 col-sm-6 text-center">
                                    <img className="my-auto w-100" src={APD.apdFeatureNetworkLogsEncryption} />
                                </div>
                            </div>
                            <div className="row text-black feature-element element-column-reverse">
                                <div className="col-lg-6 col-sm-6 text-center d-flex">
                                    <img className="my-auto w-100" src={APD.apdFeatureTroubleshootFaster} />
                                </div>
                                <div className="col-lg-6 col-sm-6 m-auto text-right">
                                    <h3 className="poppins-semi-bold"><span className="text-primary-red">Troubleshoot Faster with </span>Instant Alarms and Alerts</h3>
                                    <h6 className="dmsans-regular margin-left-auto">
                                        Instant alarms and alerts ensure that every network change is tracked in real-time, providing immediate detection and rapid response capabilities for efficient troubleshooting in defense networks.
                                    </h6>
                                </div>
                            </div>
                            <div className="row text-black feature-element">
                                <div className="col-lg-6 col-sm-6 m-auto">
                                    <h3 className="poppins-semi-bold">Document your Network in <span className="text-primary-red">Real-time</span></h3>
                                    <h6 className="dmsans-regular">
                                        Real-time network documentation captures all network changes automatically, removing the need for manual entries and providing precise data for efficient network management, essential for mission-critical environments.
                                    </h6>
                                </div>
                                <div className="col-lg-6 col-sm-6 text-center">
                                    <img className="my-auto w-100" src={APD.apdFeatureDocumentNetwork} />
                                </div>
                            </div>
                            <div className="row text-black feature-element element-column-reverse">
                                <div className="col-lg-6 col-sm-6 text-center">
                                    <img className="my-auto w-100" src={APD.apdFeatureComprehensiveInsights} />
                                </div>
                                <div className="col-lg-6 col-sm-6 m-auto text-right">
                                    <h3 className="poppins-semi-bold">Comprehensive Insights in One <span className="text-primary-red">Interactive Dashboard  </span></h3>
                                    <h6 className="dmsans-regular margin-left-auto">
                                        Gain real-time insights into network changes, system status, and connections with a centralized dashboard, improving overall monitoring and management.
                                    </h6>
                                </div>
                            </div>
                            <div className="row text-black feature-element">
                                <div className="col-lg-6 col-sm-6 m-auto">
                                    <h3 className="poppins-semi-bold"><span className="text-primary-red">Check the Health </span>of your Network Devices in Seconds </h3>
                                    <h6 className="dmsans-regular">
                                        Robust diagnostic assessments and performance evaluations proactively monitor devices, detect potential issues, and ensure your devices remain fully operational.
                                    </h6>
                                </div>
                                <div className="col-lg-6 col-sm-6 text-center d-flex">
                                    <img className="my-auto w-100" src={APD.apdFeatureHealthCheck} />
                                </div>
                            </div>
                            <div className="row text-black feature-element element-column-reverse">
                                <div className="col-lg-6 col-sm-6 text-center">
                                    <img className="my-auto w-100" src={APD.apdFeatureIdentifyPathsandPorts} />
                                </div>
                                <div className="col-lg-6 col-sm-6 m-auto text-right">
                                    <h3 className="poppins-semi-bold">Quickly Identify Network Ports and Paths to<span className="text-primary-red"> Counter Vulnerabilities</span></h3>
                                    <h6 className="dmsans-regular margin-left-auto">
                                        Identifying network ports and paths enables rapid detection and mapping of network connections, allowing for swift risk mitigation and enhanced overall protection.
                                    </h6>
                                </div>
                            </div>
                        </div>
                        {/* APD Features End */}


                        {/* APD Usecase Start */}
                        <div className="col-lg-12 container apd-usecase-section text-center">
                            <h2 className="poppins-regular text-black font-weight-400">Real-World Applications of AllPath® Director Vision </h2>
                            <div className="row">
                                {APD.usecases.map((value, index) => {
                                    return (
                                        <div className="col-md-3">
                                            <div className="bg-purple use-case-wrapper">
                                                <h6 className="poppins-regular text-white font-weight-600" dangerouslySetInnerHTML={{ __html: value.title }}></h6>
                                            </div>

                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                        {/* APD Usecase end */}

                        {/* More Products Section Start */}
                        <MoreProducts products={APD.products} />
                        {/* More Product Section End */}
                    </div>
                </div>
                <Footer />
            </div >
        </>
    );
}