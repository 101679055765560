import Footer from "../footer/footer";
import HelpCenter from "../help-center/help-center";
import Nav from "../nav/nav";
import "./about-us.scss";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from 'react-helmet';
import LocationsModal from "../locations-modal/locations-modal";
import AnimatedText from "../animated-text/animated-text";
import React, { useState } from 'react';

export default function AboutUs() {
        const [isVisible, setIsVisible] = useState(false);

        const toggleVisibility = () => {
            setIsVisible(!isVisible);
        };
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            arrows: true,
            slidesToShow: 4,
            className: "slider variable-width",
            vertical: false,
            responsive: [
                {
                    breakpoint: 600, // Breakpoint for mobile devices
                    settings: 'unslick', // Remove the slider on mobile
                },
            ],
        }
        const AboutUs = {
            bulb: '/company/about-us/bulb.png',
            worldMap: '/company/contact-us/world-map.png',
            quote: '/company/about-us/quote.png',
            glcStory: '/company/about-us/glc-story.png',
            glcVision: '/company/about-us/fm-vision.png',
            CEOMessage: '/company/about-us/ceo-message-headshot.png',
            glcMission: '/company/about-us/fm-mission.png',
            worldMap: '/company/contact-us/world-map.png',
            worldMapMobile: '/company/contact-us/world-map-mobile.png',
            options: {
                modalName: 'view-addresses',
                title: ' Our Locations'
            },
            glcLeadership: [
                {
                    image: '/company/about-us/raza-headshot.png',
                    name: 'M.H Raza',
                    position: 'CEO',
                },
                {
                    image: '/company/about-us/arsalan-headshot.png',
                    name: 'Ahmed Arsalan',
                    position: 'CTO',
                },
                {
                    image: '/company/about-us/jose-headshot.png',
                    name: 'Jose Pats',
                    position: 'VP Marketing & Product',
                },
                {
                    image: '/company/about-us/asang-headshot.png',
                    name: 'Asang Cooc',
                    position: 'Director, Sales',
                },
                {
                    image: '/company/about-us/usman-headshot.png',
                    name: 'Usman Nasir',
                    position: 'Director Business Strategy',
                },
                {
                    image: '/company/about-us/krishna-headshot.png',
                    name: 'Krishna Ogety',
                    position: 'Director Operations',
                }
            ],
        }
        return (
            <>
                <div>
                    <Helmet>
                        <title>Fiber Mountain | About Us </title>
                        <meta name="description" content="We are leading the global shift to secure, automated networks by integrating security and intelligence in network infrastructures." />
                        {/* Add more meta tags as needed */}
                    </Helmet>
                </div>
                <div className="about-us-wrapper bg-white text-white">
                    <div className="hero position-relative">
                        {/* Nav Start */}
                        <Nav />
                        {/* Nav End */}
                        <div className="hero-content hero-bg bg-blue d-flex justify-content-center align-items-center flex-column position-relative">
                            <div className="hero-heading-bg"><h1 className="poppins-semi-bold text-white text-center hero-heading">About Us </h1></div>
                            <h6 className="dmsans-regular text-white hero-subheading text-center">Accelerating the transition to secure, automated networks globally</h6>
                        </div>
                    </div>
                    <div className="our-story-bg">
                        <div className="container">
                            <div className="glc-story row">
                                <div className="col-sm-4"><h2 className="poppins-regular text-black story-heading">Our Story</h2></div>
                                <div className="col-sm-8">
                                    <h6 className="text-black dmsans-regular text-story about-info">
                                    Fiber Mountain was established in October of 2021, with an intent to manage the entire data center network with Artificial Intelligence.  Software would be used to discover the vitals of a network in “real time”.  Switches, routers, servers, cabinets, patch panels, cables, and their connectivity maps with accurate physical location would be known at any given point in time.
                                    </h6>
                                    <h6 className="text-black dmsans-regular text-story about-info mt-4">The technology was called SENSUS™ by its founder. Much like the word Census, which accounts for inhabitants of a city, the people, their children, their parents, their relationships with each other, and the knowledge of where they live relative to the entire city is captured in a census. SENSUS™ would do the same for a data center.
                                    </h6>
                                </div>
                                {isVisible && (
                                <div className="col-sm-8 offset-sm-4 mt-4">
                                    <h6 className="text-black dmsans-regular text-story about-info">
                                    Sensor technology was evolving, software was going to command how we managed data center infrastructures. The addition of software changed taxis to Uber; the addition of software would change management of data centers to the Fiber Mountain way.
                                    </h6>
                                    <h6 className="text-black dmsans-regular text-story about-info mt-4">The original Fiber Mountain (FM) started in 2013, and its early concepts and lab trials were promising, and the benefits of its solutions were obvious to large customers. FM started to work with a Hyperscale customer’s Labs, understanding the needs of managing large data centers. It was working with one of best data center companies in the world, and key engineers who had worked with Steve Jobs in his early days were evaluating what FM was building. Real data center hurdles were discussed, plans were made, concepts were demonstrated, and FM was on its way to product development. The 7-year experience working with the Hyperscale Data Center labs was perhaps FM’s most prized experience in the space of High Performance Compute (HPC) data centers.
                                    </h6>
                                    <h6 className="text-black dmsans-regular text-story about-info mt-4">The company roadmap of old FM was very hardware R&D centric, while the world was moving to Artificial Intelligence.  This was perfect timing for the founders of FM to focus on commercializing software driven solutions that would address real world market requirements. Here, a company called Green Lambda Corporation (GLC) was formed in September 2021. This left a major void on the original FM and the company struggled for its survival.
                                    </h6>
                                    <h6 className="text-black dmsans-regular text-story about-info mt-4">GLC raised capital for its new software IP.  Nine months after its start, in July 2022, GLC will acquire FM’s assets including its IP. The original founders had acquired their old company, and another 6 months later GLC will converge to original name for brand loyalty; the company was called Fiber Mountain again.
                                    </h6>
                                    <h6 className="text-black dmsans-regular text-story about-info mt-4">The new Fiber Mountain had two tasks at hand. Firstly, they re-established the requirements of key customers such as its large Hyperscaler and US Pentagon who had lost interest in old FMs direction and had to innovate and gain recognition for its AI/MS driven solutions.
                                    </h6>
                                    <h6 className="text-black dmsans-regular text-story about-info mt-4">Today Fiber Mountain is the world’s leading company in real time, sensor-based automation technology for data center assets. Commercialized technologies are available for industry vendors to license, and FM is evaluating value-add partners in racks & cabinets, patch panels, and cable space.  FM will offer these hardware incumbents its IP and software to automate their customers’ data centers with AI/ML. With this collaborative business model FM can focus on innovation in software and enable connectivity hardware OEMs to upgrade their solutions efficiently and sustainably, for the betterment of the industry.   Included in the offerings from FM is licensing for the world’s first locking connector technology, the ability to lock or unlock any remote port in the network using software. This is a leapfrog for security and programmability of WANs, decentralized networks, edge compute nodes, and all ultra-high-density AI/ML backbones.
                                    </h6>
                                    <h6 className="text-black dmsans-regular text-story about-info-bold mt-4">Welcome to the new Fiber Mountain!</h6>
                                </div>
                                  )}
                                <div className="col-sm-12 offset-sm-4">
                                    <Link onClick={toggleVisibility} className="poppins-bold solutions-link bg-primary-color border-primary-color btn-story"> <h6 className="dmsans-regular font-weight-400 mt-3">{isVisible ? 'See Less!' : 'See More!'}</h6></Link>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="bg-pink vision-mission-wrapper">
                        <div className="container">
                            <h5 className="text-center poppins-regular font-weight-600 dream-heading">Imagine a reality where intelligence takes over your workload.</h5>
                            <h2 className="poppins-regular text-center text-black header">This is more than just a dream. <span>It's the reality we create, and it's what we excel at.</span></h2>
                            <div className="glc-mission">

                                <div className="row text-black">
                                    <div className="col-lg-6 col-sm-6 m-auto">
                                        <h3 className="poppins-semi-bold">Our Mission</h3>
                                        <h6 className="dmsans-regular mission-text">
                                            We bring a brand-new perspective to managing networks with only one objective integrating security and intelligence at the physical layer to streamline data center operations, saving you time, effort, and costs.
                                        </h6>
                                    </div>
                                    <div className="col-lg-6 col-sm-6 vision-image-wrapper">
                                        <img className="my-auto" src={AboutUs.glcMission} />
                                    </div>

                                </div>

                            </div>
                            <div className="glc-vision">
                                <div className="row text-black our-vision-wrapper">
                                    <div className="col-lg-6 col-sm-6">
                                        <img className="my-auto" src={AboutUs.glcVision} />

                                    </div>
                                    <div className="col-lg-6 col-sm-6 m-auto">
                                        <h3 className="poppins-semi-bold">Our Vision</h3>
                                        <h6 className="dmsans-regular vision-text">
                                            Our vision is to lead the global revolution towards secure, automated networks, setting a new standard for connectivity and efficiency. We aim to revolutionize data centers with innovative solutions that enhance security, efficiency, and automation, paving the way for a more digitized and secure world.
                                        </h6>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="container">
                        {/* <div className="glc-leadership bg-white">
                        <div className="text-center">
                            <h2 className="text-black poppins-regular">Leadership Team</h2>
                            <h5 className="text-black dmsans-regular text-behind-brand about-info">Seasoned professionals having a track record of excellence in the networking industry</h5>
                        </div>
                        <div className="leadership-container row justify-content-center">
                            {AboutUs.glcLeadership.map((value, index) => {
                                return (
                                    <div className="leader-element col-lg-3">
                                        <img src={value.image} />
                                        <div className="leader-info">
                                            <h3 className="poppins-semi-bold mt-4 text-black leader-name" dangerouslySetInnerHTML={{ __html: value.name }}></h3>
                                            <h5 className="poppins-regular text-black">{value.position}</h5>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div> */}
                        <div className="ceo-message-wrapper bg-white row">

                            <div className="col-sm-7">
                                <h2 className="text-black poppins-regular mb-4 message-heading">CEO'S Message</h2>
                                <div className="content">
                                    <div className="quote-margin">
                                        <img src={AboutUs.quote} />
                                    </div>
                                    <h6 className="text-black message-text poppins-regular">At Fiber Mountain, we are driven by a relentless commitment to innovation and excellence in data center solutions. Our mission is to empower data centers with cutting-edge technology that enhances performance, optimizes operations, and ensures robust security.</h6>

                                    <h6 className="text-black message-text poppins-regular">From our certified products to our dedicated team, every aspect of our approach is designed to meet the evolving needs of modern businesses. Whether you're looking to streamline operations, maximize profitability, or fortify your network security, Fiber Mountain is here to partner with you on your journey to success. </h6>
                                    <h6 className="text-black message-text poppins-regular mb-0">Welcome to Fiber Mountain! </h6>
                                    <div className="text-right mt-0">
                                        <img src={AboutUs.quote} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-5">
                                <img className="w-100" src={AboutUs.CEOMessage} />
                            </div>
                        </div>

                        <HelpCenter />
                    </div>
                    <div>
                        <div className="container text-center">
                            <h2 class="text-black text-center poppins-regular">Our Locations</h2>
                            <Link className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color view-apd-btn mt-5" data-bs-target={'#' + AboutUs.options.modalName} data-bs-toggle="modal">
                                View Addresses
                            </Link>
                            <LocationsModal options={AboutUs.options} />
                        </div>
                        <img className="w-100 d-md-block d-none world-map" src={AboutUs.worldMap} />
                        <img className="w-100 d-md-none d-block world-map" src={AboutUs.worldMapMobile} />
                    </div>

                    {/* Footer Start */}
                    <Footer />
                    {/* Footer End */}
                </div >
            </>
        );
    }