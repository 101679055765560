import "./home.scss";
import Nav from "../nav/nav";
import Footer from "../footer/footer";
import { Link } from "react-router-dom";
import AnimatedText from "../animated-text/animated-text";
import { Helmet } from 'react-helmet';
import Slider from "react-slick";
export default function Home() {

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const homeAssets = {
    enterpriseIcon: '/solutions/icons/enterprise-icon-black.png',
    federalIcon: '/solutions/icons/federal-icon-black.png',
    networkAutomation: '/expertise/network-operation-automation.png',
    partnerLogosMobile: '/backgrounds/partner-logos-mobile.svg',
    partnerLogosSmallLaptop: '/backgrounds/partner-logos-992.svg',
    allProductsURL: '/all-products',
    ourProducts: [
      {
        icon: '/products/apd-screen-vision.png',
        tag: 'JTIC Certified Software',
        name: 'AllPath® Director Vision',
        info: 'Network Management Software that automates the documentation & management of patch panels with an added layer of security, custom-made for mission-critical environments.',
        link: '/products/apd-vision'

      },
      {
        icon: '/products/simplphy-screen-icon.png',
        tag: 'SaaS Platform',
        name: 'SiMPLphy',
        info: 'Advanced Saas-based connectivity documentation and management platform that optimizes the management of Data Centers.',
        link: 'https://www.simplphy.com/'

      },
      {
        icon: '/products/apd-screen-starling.png',
        tag: 'Flagship Software',
        name: 'AllPath® Director Starling',
        info: 'Network Management Software that automates the documentation & management of patch panels, tailored for Enterprise Networks.',
        link: '/products/apd-starling'

      }],
    ourFederalSolutions: [

      {
        name: 'Network Security and Authorization Control',
        info: "FIPS-certified, TAA-compliant products provide network security with role-based access, and detailed network tracking.",
        link: ''

      },
      {
        name: 'Network Diagnostics and Troubleshooting',
        info: "Optimize device performance with APL-certified products offering proactive health monitoring, and robust diagnostics.",
        link: ''

      },
      {
        name: 'Network Capacity Planning and Resource Allocation',
        info: "Enhance operational efficiency with advanced C3I capabilities, precise port and rack monitoring, real-time updates, and comprehensive user visibility.",
        link: ''

      },
      {
        name: 'Real-time Network Monitoring and Administration ',
        info: "Our JTIC and APL certified products provide real-time visibility and automated documentation for comprehensive network oversight.",
        link: ''

      }
    ],
    ourEnterpriseSolutions: [

      {
        name: 'Capacity Planning and Resource Management',
        info: "Foster efficient infrastructure and resource planning through network wide visibility to enhance performance and ensure seamless connectivity.",
        link: ''

      },
      {
        name: 'Security and Access</br> Control ',
        info: "Manage access permissions and track every network change to ensure robust network security and uninterrupted network operations.",
        link: ''

      },
      {
        name: 'Network Diagnostics and Troubleshooting',
        info: "Achieve optimal functionality through health checks and diagnostic assessments of network devices to ensure smooth network operations.",
        link: ''
      },
      {
        name: 'Data Center and Infrastructure Management',
        info: "Improve infrastructure through assets and connections monitoring, lifecycle tracking of assets and rigorous equipment maintenance.",
        link: ''
      },

    ],
    resourcesSlides: [
      {
        img: "/home-resources-slides/resource-slide-1.png",
        title: "What is the Fiber Mountain Solution?",
        description: "Discover how Fiber Mountain simplifies re-configuring infrastructure connections, providing operational benefits and reducing costs.",
        link: "/resources/whitepapers/Fiber-Mountain-Solution.pdf"
      },
      {
        img: "/home-resources-slides/resource-slide-2.png",
        title: "Fiber Mountain's Zero Trust Managed Patching Solution ",
        description: "Read how Fiber Mountain's managed patching solution marks a significant advancement in data center security.",
        link: "/resources/whitepapers/Fiber-Mountain-Zero-Trust-Managed-Patching-Solution.pdf"
      },
      {
        img: "/home-resources-slides/resource-slide-3.png",
        title: "AllPath® Director | Network Management Software",
        description: "A key component of the Fiber Mountain solution, AllPath® Director is a network management software that streamlines data center management.",
        link: '/resources/datasheets/AllPath-Director.pdf'
      },
      {
        img: "/home-resources-slides/resource-slide-4.png",
        title: "Sensus™ Premium Fiber Patch Panel",
        description: "Read how Sensus™ patch panel and Fiber Mountain solutions innovate to efficiently manage escalating data and cable demands.",
        link: "/resources/datasheets/Sensus.pdf"
      },
      {
        img: "/home-resources-slides/resource-slide-5.png",
        title: "Physical Layer 2.0 | Secure and Intelligent",
        description: "To enhance data center agility, we reimagine the physical layer with integrated security and intelligence, the cornerstone of network activity.",
        link: "/resources/ebooks/Fiber-Mountain-Physical-Layer-2.0.pdf"
      },

    ]
  }
  return (
    <>
      <div>
        <Helmet>
          <title>Fiber Mountain | Enabling Secure, Automated Networks Globally</title>
          <meta name="description" content="Fiber Mountain is a leading product company that offers integrated software and hardware products for data center management." />
          {/* Add more meta tags as needed */}
        </Helmet>
      </div>
      <div className="bg-white">
        <Nav />
        <div className="banner-bg">
          <div className="container">
            {/*  Home Banner Section */}
            <div className="home-banner">
              <div className="row">
                <div className="col-sm-12 text-center">
                  <h1 className="text-white z-index-1 banner-heading poppins-regular">
                    We <span className="font-weight-600">Secure</span> and <span className="font-weight-600">Automate</span> Data Centers with Cutting-Edge <span className="font-weight-600">Software</span> Solutions </h1>
                  <div className="diagram mt-5">
                  </div>
                </div>
              </div>
            </div>
            {/*  Home Banner Section End */}
          </div>
        </div>
      </div >
      <div className="bg-white">
        <div className="container">
          {/* Solutions Segmentation Section Start */}
          <div className="solutions-segmentation text-white">
            <div className="segmentation-container">
              <div className="segmentation-bg" >
                <div className="d-flex flex-column">
                  <div className="flex-item">
                    <img className="icon-size" alt="Enterprise Solutions" src={homeAssets.enterpriseIcon} />
                  </div>
                  <div className="flex-item fill">
                    <h4 className="poppins-bold text-blue-primary segmentation-heading">
                      Enterprise Solutions
                    </h4>
                    <p className="segmentation-info dmsans-regular">
                      State-of-the-art products aimed at optimizing business operations and growth, with a focus on Security, Serviceability, and Workflow Automation.
                    </p>
                  </div>
                  <div className="flex-item">
                    <a className="btn secondary-btn bg-primary-color text-white border-primary-color mt-auto" href="/coming-soon">
                      Learn more
                    </a>
                  </div>
                </div>

              </div>
              <div className="segmentation-bg">
                <div className="d-flex flex-column flex-container">
                  <div className="flex-item">
                    <img className="icon-size" alt="Federal Solutions" src={homeAssets.federalIcon} />
                  </div>
                  <div className="flex-item fill">
                    <h4 className="poppins-bold text-blue-primary segmentation-heading">
                      Federal Solutions
                    </h4>
                    <p className="segmentation-info dmsans-regular">
                      Secure, efficient, and resilient solutions for mission-critical environments.
                    </p>
                  </div>
                  <div className="flex-item">
                    <a className="btn secondary-btn bg-primary-color text-white border-primary-color mt-auto align-self-end" href="/coming-soon">
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
          {/* Solutions Segmentation Section End */}
        </div>

        {/* Partners Section */}
        <div className="partners-section">
          <div className="partners-padding">
            <div className="container">
              <h2 className="glc-products-heading poppins-regular text-blue-primary">
                Secure and <span className="font-weight-600">Optimize</span> Your <span className="font-weight-600">Global</span> <span className="font-weight-600">Networks</span> with Us
              </h2>
            </div>
            <div className="partners-background">
              <div className="partners-grid">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <h6 className="text-black dmsans-regular partners-info">Trusted by top Fortune 500 companies and federal government agencies, Fiber Mountain is a leading product company that develops integrated software and hardware solutions to optimize data center management. Our integrated approach empowers you to seamlessly track, monitor, and control your data center operations with unmatched precision and efficiency.</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="partners-mobile-img">
                <img src={homeAssets.partnerLogosMobile} className="w-90"/>
              </div>
              <div className="partners-small-laptop">
                <img src={homeAssets.partnerLogosSmallLaptop} className="w-100"/>
              </div>
              <div className="partners-small-tab">
                <img src={homeAssets.partnerLogosMobile} className="w-90"/>
              </div>
            </div>

          </div>
        </div>
        {/* Partners Section End */}

        {/* Expertise Section Start */}
        <div className="bg-pink expertise-section">
          <div className="container">
            <h2 className="poppins-regular text-center  text-blue-primary expertise-heading">Explore Our <span className="font-weight-600">Expertise</span></h2>

            <div className="expertise-grid text-white">
              <div className="column column-one">
                <div className="image-container">
                  <img src={homeAssets.networkAutomation} />
                </div>
                <div className="expertise-content">
                  <h3 className="poppins-regular font-weight-600 expertise-subheading">Network Operations Automation</h3>
                  <h6 className="dmsans-regular text-product">
                    Manual network management is time-consuming and prone to errors. Our products automate your network operations, enhancing efficiency and cutting operational costs. This automation enables improved data center administration and faster response to network changes and security concerns. </h6>
                </div>
              </div>
              <div className="column column-two">
                <div className="grid-two">
                  <div className="grid-two-column-one">
                    <h3 className="poppins-regular font-weight-600 ">Port & User-Level Security </h3>
                    <h6 className="dmsans-regular text-product">
                      In today’s evolving threat landscape, our zero-trust products stand out by offering unparalleled security never seen before. With port locking and user-level controls, we rigorously authenticate and authorize, providing unmatched protection and network-wide visibility to safeguard your digital assets. </h6>
                  </div>
                  <div className="grid-two-column-two">
                    <h3 className="poppins-regular font-weight-600 ">AI-Powered Analytics</h3>
                    <h6 className="dmsans-regular text-product">
                      We leverage AI you can trust to transform your data into actionable insights, ensuring secure and transparent operations. Our solutions analyze data in real-time, providing comprehensive visibility of your network’s performance. This helps in making informed decisions, resource optimization, and foreseeing future challenges. </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Expertise Section End */}

        {/* Solutions Section Start */}
        <div className="bg-white our-solutions-container">
          <h2 className="solutions-heading poppins-regular text-black text-center">
            Tough challenges? Tailored solutions.
          </h2>
          <div className="home-solutions-bg">
            <div className="container">
              <div className="d-flex solutions-flex-container">
                <div className="bg-pink solutions-element">
                  <div className="d-flex justify-content-center">
                    <h5 className="badge poppins-regular">Federal Solutions</h5>
                  </div>

                  {homeAssets.ourFederalSolutions.map((value, index) => {
                    return (
                      <div className="text-black">
                        <div className="flex-grow-1">
                          <h4><span className="badge poppins-regular">{value.tag}</span></h4>
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="poppins-regular text-element"> <span dangerouslySetInnerHTML={{ __html: value.name }}></span></h5>
                        </div>

                        <div className="flex-grow-1">
                          <p className="solution-text dmsans-regular">{value.info}</p>
                        </div>
                        {/* <div className="flex-grow-1">
                          <Link to={value.link} className="poppins-bold solutions-link bg-primary-color border-primary-color btn-read-more">See More</Link>
                        </div> */}
                        <hr className="hr-footer hr-solutions"></hr>
                      </div>
                    )
                  })}
                </div>
                <div className="bg-pink solutions-element">
                  <div className="d-flex justify-content-center">
                    <span className="badge poppins-regular">Enterprise Solutions</span>
                  </div>

                  {homeAssets.ourEnterpriseSolutions.map((value, index) => {
                    return (
                      <div className="text-black">
                        <div className="flex-grow-1">
                          <h4><span className="badge poppins-regular">{value.tag}</span></h4>
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="poppins-regular text-element"> <span dangerouslySetInnerHTML={{ __html: value.name }}></span></h5>
                        </div>
                        <div className="flex-grow-1">
                          <p className="solution-text dmsans-regular">{value.info}</p>
                        </div>
                        {/* <div className="flex-grow-1">
                          <Link to={value.link} className="poppins-bold solutions-link bg-primary-color border-primary-color btn-read-more">See More</Link>
                        </div> */}
                        <hr className="hr-footer hr-solutions"></hr>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Solutions Section End */}

        {/* Products Section End */}
        <div className="bg-white our-products-container">
          <div className="container">
            <h2 className="products-heading poppins-regular text-black">
              Featured Products
            </h2>
            <div className="row text-center w-100 products-wrapper">
              {homeAssets.ourProducts.map((value, index) => {
                return (
                  <div className="col-lg-4 col-sm-4">
                    <div className="product-element bg-pink text-center text-black">
                      <div className="flex-grow-1">
                        <img className="image-margin" src={value.icon} />
                      </div>
                      <div className="flex-grow-1">
                        <span className="badge poppins-regular">{value.tag}</span>
                      </div>
                      <div className="flex-grow-1">
                        <h4 className="poppins-semi-bold font-weight-600 text-element"> <span dangerouslySetInnerHTML={{ __html: value.name }}></span></h4>
                      </div>
                      <div className="flex-grow-1">
                        <p className="product-text dmsans-regular">{value.info}</p>
                      </div>
                      <div className="flex-grow-1">
                        <Link to={value.link} className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color mt-2 btn-read-more">LEARN MORE</Link>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        {/* Products Section Start */}

        {/* Resource Section Start */}
        <div className="bg-white resource-container">
          <h2 className="fm-resource-heading poppins-regular text-center text-black">
            Resource Library
          </h2>
          <div className="row text-center w-100">
            <div className="slider-container">
              <Slider {...settings}>
                {homeAssets.resourcesSlides.map((value, index) => {
                  return (
                    <div class="d-flex flex-column">
                      <div>
                        <img src={value.img} />
                      </div>
                      <div className="content bg-pink">
                      <h6 className="poppins-semi-bold font-weight-600 slide-title"><Link className="resource-text-url" target="_blank" to={value.link}>{value.title}</Link></h6>
                        <p className="product-text dmsans-regular">{value.description}</p>
                      </div>
                    </div>

                  )
                })}
              </Slider>
            </div>
          </div>
        </div>
        {/* Resource Section End */}
      </div >
      <Footer />
    </>
  );
}
