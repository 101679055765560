import Footer from "../footer/footer";
import Nav from "../nav/nav";
import "./error-404.scss";
import { Link} from "react-router-dom";

export default function Error404() {
    return (
        <div className="error-wrapper bg-blue">
            {/* Capacity Management Hero Section Start */}
            <div className="hero position-relative">
                {/* Nav Start */}
                <Nav />
                {/* Nav End */}
                <div className="hero-content d-flex justify-content-center align-items-center error-bg text-white flex-column hero-bg">
                    <div className="lines-left">
                    </div>
                    <div className="bg-gradient-left">
                    </div>
                    <div className="wire-left">
                    </div>
                    <h1 className="error-heading nexa-bold">404</h1>
                    <p className="error-info dmsans-regular mb-0">This page is not available.</p>
                    <Link to="/" className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color error-btn">
                            HOME PAGE
                    </Link>
                    <div className="lines-right">
                    </div>
                    <div className="bg-gradient-right">
                    </div>
                    <div className="wire-right">
                    </div>
                </div>
            </div>
            {/* Capacity Management Hero Section Ends */}
            {/* Footer Start */}
            <Footer />
            {/* Footer End */}
        </div >
    );
}