import "./contact-us.scss";
import Footer from "../footer/footer";
import Nav from "../nav/nav";
import React, { useEffect, useRef, useState } from "react";
import HelpCenter from "../help-center/help-center";
import { useLocation } from "react-router-dom";
import LocationsModal from "../locations-modal/locations-modal";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import axios from 'axios';
// import './EmailForm.css';

export default function Contact() {
    const { hash } = window.location;
    const contactRef = useRef(null);
    const demoRef = useRef(null);
    const resellerRef = useRef(null);

    const ContactUs = {
        worldMap: '/company/contact-us/world-map.png',
        worldMapMobile: '/company/contact-us/world-map-mobile.png',
        options: {
            modalName: 'view-addresses',
            title: 'Our Locations'
        },
    }
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        companyName: '',
        helpMessage: '',
    });

    const [errors, setErrors] = useState({});
    const [responseMessage, setResponseMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const validatePhoneNumber = (number) => {
        const phoneRegex = /^[0-9()+\-.\sx ]*$/;
        return phoneRegex.test(number);
      };

    const validate = () => {
        let tempErrors = {};
        let isValid = true;

        if (!formData.firstName) {
            tempErrors.firstName = "First Name is required.";
            isValid = false;
        }
        if (!formData.lastName) {
            tempErrors.lastName = "Last Name is required.";
            isValid = false;
        }
        if (!formData.email) {
            tempErrors.email = "Email is required.";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            tempErrors.email = "Email is not valid.";
            isValid = false;
        }
        if (!formData.phone) {
            tempErrors.phone = "Phone Number is required.";
            isValid = false;
        }
        else if (!validatePhoneNumber(formData.phone)) {
            tempErrors.phone = "Invalid phone number format. A valid phone number may only contain numbers, +()-. or x";
            isValid = false;
        }
        if (!formData.companyName) {
            tempErrors.companyName = "Company Name is required.";
            isValid = false;
        }
        if (!formData.helpMessage) {
            tempErrors.helpMessage = "This field is required.";
            isValid = false;
        }

        setErrors(tempErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            // Clear previous messages
            setErrorMessage('');
            setResponseMessage('');

            // Make the POST request using axios
            axios.post('https://bwtcv4iwxn7rsdwi52l4b3yvly0evhlm.lambda-url.us-east-1.on.aws/', formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    setResponseMessage('Thank you! Your response has been successfully submitted.');

                })
                .catch(error => {
                    console.error('There was an error sending the email!', error);
                    setErrorMessage('There was an error in form submission!');
                });
        }
    };


    return (
        <>
            <div>
                <Helmet>
                    <title>Fiber Mountain | Contact Us</title>
                    <meta name="description" content="Connect with Fiber Mountain for expert support and inquiries about our cutting-edge data center solutions." />
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div className="contact-us bg-white">
                {/* Nav Start */}
                <Nav />
                {/* Nav End */}
                <div className="contact-page">
                    <div className="contact-tile-container d-flex justify-content-center">
                        <div className="contact-title-content text-center">
                            <h2 className="contact-title poppins-regular"><span className="poppins-semi-bold">Let's</span> Talk
                            </h2>
                            <h5 className="dmsans-regular text-white contact-subtitle ">We would love to hear from you, get in touch with us now!  </h5>
                        </div>
                    </div>
                    <div className="container">
                        <div className="contact-form-container mx-auto bg-pink">
                            <h3 className="poppins-regular text-black form-title text-center" ref={contactRef}>Please fill out this form</h3>

                            <form className="contact-us-form" onSubmit={handleSubmit}>
                                <div className="form-row">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="firstName"
                                            value={formData.firstName}
                                            onChange={handleChange} placeholder="First Name*"
                                            required
                                        />
                                        {errors.firstName && <div className="error">{errors.firstName}</div>}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="lastName" placeholder="Last Name*"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            required
                                        />
                                        {errors.lastName && <div className="error">{errors.lastName}</div>}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        name="email" placeholder="Email*"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                    {errors.email && <div className="error">{errors.email}</div>}
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="phone" placeholder="Phone*"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        required
                                    />
                                    {errors.phone && <div className="error">{errors.phone}</div>}
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="companyName"
                                        value={formData.companyName} placeholder="Company Name*"
                                        onChange={handleChange}
                                        required
                                    />
                                    {errors.companyName && <div className="error">{errors.companyName}</div>}
                                </div>
                                <div className="form-group">
                                    <textarea
                                        name="helpMessage"
                                        value={formData.helpMessage} rows="4" placeholder="How can we help?*"
                                        onChange={handleChange}
                                        required
                                    />
                                    {errors.helpMessage && <div className="error">{errors.helpMessage}</div>}
                                </div>
                                <p className="dmsans-regular mt-4">Fiber Mountain is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick the checkbox below:</p>
                                <div className="checkbox-input">
                                    <ul className="inputs-list">
                                        <li className="hs-form-booleancheckbox">
                                            <label className="hs-form-booleancheckbox-display">
                                                <input className="hs-input" type="checkbox" name="checkbox-input" value="true" />
                                                <span classname="checkbox-input-span">I agree to receive other communication from Fiber Mountain.
                                                </span>

                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                <p className="dmsans-regular mt-2">You may unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our <a href="/privacy-policy" target="_blank" rel="noopener">Privacy Policy</a></p>
                                <p className="dmsans-regular mt-2">By clicking <strong>Submit</strong>, you consent to allow Fiber Mountain to store and process the personal information submitted above in order to provide you with the requested content.</p>
                                {responseMessage && <div className="success-message">{responseMessage}</div>}
                                {errorMessage && <div className="error-message">{errorMessage}</div>}
                                <button className="primary-btn btn text-white mt-4 font-weight-700" type="submit">Submit</button>
                            </form>
                        </div>
                        <HelpCenter />
                    </div>
                    <div>
                        <div className="container text-center">
                            <h2 className="text-black text-center poppins-regular">Our <span className="text-primary-red">Locations</span></h2>
                            <Link className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color view-apd-btn mt-5" data-bs-target={'#' + ContactUs.options.modalName} data-bs-toggle="modal">
                                View Addresses
                            </Link>
                            <LocationsModal options={ContactUs.options} />
                        </div>
                        <img className="w-100 d-md-block d-none world-map" src={ContactUs.worldMap} />
                        <img className="w-100 d-md-none d-block world-map" src={ContactUs.worldMapMobile} />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}