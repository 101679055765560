import "./360-view.scss";
import { React360Viewer } from "react-360-product-viewer";

export default function ThreeSixtyView(props) {

    return (
        <>
            
            <div class="modal fade modal-lg" id={props.options.modalName} tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title nexa-bold w-100 text-center" dangerouslySetInnerHTML={{__html:props.options.title}}></h4>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-center">
                            <React360Viewer
                                imagesBaseUrl={props.options.images}
                                imagesCount={props.options.imgCount}
                                imagesFiletype="jpg"
                                mouseDragSpeed={20}
                                reverse={props.options.isReverse}
                                autoplay
                                autoplaySpeed={props.options.playSpeed}
                                className="abc"
                                width={props.options.imgWidth}
                                height={props.options.imgHeight}
                            />
                            <p className="text-center">Hold-and-drag to view the device in 360</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}