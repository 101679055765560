
import Footer from "../footer/footer";
import Nav from "../nav/nav";
import "./coming-soon.scss";
import { Helmet } from 'react-helmet';

export default function EnterpriseSolutions() {
    const Solutions = {
        enterpriseCapacityManagement: '/solutions/all-solutions/enterprise-solution-capacity-resource-management.png',
        enterpriseSecurityAccess: '/solutions/all-solutions/enterprise-solution-security-access-control.png',
        AIReports: '/solutions/all-solutions/enterprise-solution-ai-driven-reports-ai-network-deployments.png',
        networkDiagnostics: '/solutions/all-solutions/enterprise-solution-network-diagnostics-troubleshooting.png',
        dataCenterInfrastructure: '/solutions/all-solutions/enterprise-solution-data-center-infrastructure-management.png',
    }

    return (
        <div className="bg-white">
            <div>
                <Helmet>
                    <title>Fiber Mountain | COMING SOON</title>
                    <meta name="description" content="Transform your business with our top-quality data center products for mission-critical and enterprise networks." />
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div className="all-solutions-wrapper bg-white">
                {/* All Solutions Hero Section Start */}
                <Nav />
                <div className="hero">
                    <div className="hero-content d-flex justify-content-center align-items-center flex-column position-relative">
                        <h1 className="poppins-regular hero-sub-heading text-white">
                        FIBER MOUNTAIN SOLUTIONS
                        </h1>
                        <h3 className="text-white dmsans-regular hero-content-sub-heading">COMING SOON </h3>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}