import Footer from "../footer/footer";
import Nav from "../nav/nav";
import "./privacy-policy.scss";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
    // Define constants for privacy policy content
    const FIBER_MOUNTAIN_MISSION = 'Fiber Mountain is committed to transforming network infrastructure, and to providing you with the information you need to make educated decisions about the continuing evolution of your data centers.';

    const DATA_COLLECTION_DESCRIPTION = 'To do this, we constantly create new content, whether in the form of blog posts, technical data sheets, or downloadable eBooks, and we also gather and analyze a variety of data that helps us to understand what content is most useful to our visitors and how you prefer to engage with us. We appreciate the trust you show by sharing your information with us and promise to respect your privacy.';

    const EMAIL_USAGE_DESCRIPTION = 'Fiber Mountain uses information you provide, such as your email address, in order to send you information you request, as well as other Fiber Mountain communications that may interest you. We will also work to personalize and optimize your experience of our website, to better understand how our products can meet your needs, and to put you in touch with our channel partners or resellers when you are ready to buy.';

    const OPT_OUT_DESCRIPTION = 'You can always opt out of further emails by using the opt-off link at the bottom of every email or by emailing <a class="text-url" href="mailto:info@fibermountain.com">info@fibermountain.com</a> with “Unsubscribe” in the subject line and a list of the email addresses you want to opt off.';

    const THIRD_PARTY_DESCRIPTION = 'In addition to the information you submit to us directly, we use third-party services such as Google Analytics and Hubspot to track activity on fibermountain.com and analyze which content you find most useful.';

    const COOKIE_ALERT = 'These services use cookies in order to gather data for us, which you can control via your browser settings.'
    const LAW_DECLARATION = 'The only other times Fiber Mountain will share your personal data with a third party are if required by law, or if Fiber Mountain is acquired by another company, in which case we will bring our database along in order to keep providing the personal experience you have come to expect.'
    const PRIVACY_DIVIDER = '/company/privacy-divider.png';

    return (
        <div className="error-wrapper bg-pink">
            <div className="hero position-relative">
                {/* Nav Start */}
                <Nav />
                {/* Nav End */}
                <div className="privacy-wrapper container">
                    <h1 className="poppins-regular text-black text-center">Privacy Policy</h1>
                    <img className="w-100" src={PRIVACY_DIVIDER} />
                    <h2 className="poppins-regular text-black">Our mission, your privacy</h2>
                    <h6 className="dmsans-regular text-black">{FIBER_MOUNTAIN_MISSION}</h6>
                    <h6 className="dmsans-regular text-black">{DATA_COLLECTION_DESCRIPTION}</h6>
                    <h2 className="poppins-regular text-black">We will never sell your information!</h2>
                    <h6 className="dmsans-regular text-black">{EMAIL_USAGE_DESCRIPTION}</h6>
                    <h6 className="dmsans-regular text-black" dangerouslySetInnerHTML={{__html: OPT_OUT_DESCRIPTION}}></h6>
                    <h6 className="dmsans-regular text-black">{THIRD_PARTY_DESCRIPTION}</h6>
                    <h6 className="dmsans-regular text-black">{COOKIE_ALERT}</h6>
                    <h6 className="mt-4">
                        <Link target="_blank" className="external-link" to="https://analytics.google.com/analytics/web/provision/?authuser=0#/provision">Read more about how Google Analytics works </Link>
                    </h6>
                    <h6>
                        <Link target="_blank" className="external-link" to="https://www.aboutcookies.org.uk/managing-cookies">Read more about managing your cookies</Link>
                    </h6>
                    <h6 className="dmsans-regular text-black">{LAW_DECLARATION}</h6>
                </div>

            </div>
            {/* Capacity Management Hero Section Ends */}
            {/* Footer Start */}
            <Footer />
            {/* Footer End */}
        </div >
    );
}