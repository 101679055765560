import Footer from "../footer/footer";
import HelpCenter from "../help-center/help-center";
import Nav from "../nav/nav";
import "./events.scss";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Helmet } from 'react-helmet';
import LocationsModal from "../locations-modal/locations-modal";
import AnimatedText from "../animated-text/animated-text";

export default function Events() {

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [

            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const eventsAssets = {

        eventGallery: '/company/events/events-gallery.png',
        upcomingEvents: [
            {
                index: "event-bg-1",
                booth: 'BOOTH 313',
                backgroundImage: '/company/events/upcoming-event-emerging-technologies-for-defense-2024.png',
                backgroundImageMobile: '/company/events/upcoming-event-emerging-technologies-for-defense-2024-mobile.png',
                date: 'August 7-9th, 2024',
                location: 'Walter E. Washington Convention Center, Washington',
                title: 'Emerging Technologies for Defense 2024',
                description: "Explore the forefront of defense technology with Fiber Mountain at Emerging Technologies for Defense 2024! Discover our pioneering solutions in advanced security and robust network infrastructure. Join us for an exclusive glimpse into the future of defense technology.",
                link: 'https://exhibits.ndia.org/eti2024/Public/MainHall.aspx?ID=40883&sortMenu=101000'
            },
            {
                index: "event-bg-2",
                booth: 'BOOTH 2412',
                backgroundImage: '/company/events/upcoming-event-bicsi-fall-2024.png',
                backgroundImageMobile: '/company/events/upcoming-event-bicsi-fall-2024-mobile.png',
                date: '15-19th September, 2024',
                location: 'Caesars Forum in Las Vegas, Nevada',
                title: 'BICSI Fall Conference and Exhibition 2024',
                description: "Seeking cutting-edge solutions to kickstart your business? Join us at the BICSI Fall Conference & Exhibition 2024, where Fiber Mountain will be showcasing the future of networking. Don't miss out on this opportunity to explore innovative technologies that will shape the way you connect and operate.",
                link: 'https://s23.a2zinc.net/clients/BICSI/Fall2024/Public/MainHall.aspx?ID=4282'
            },

        ],
        wrappedUpEvents: [
            {
                image: '/company/events/wrapped-up-event-3.png',
                title: 'TechNet Cyber 2024',
                description: "Experience Fiber Mountain's showcase at TechNet Cyber 2024! We presented our latest advancements in security and contributed to discussions on emerging trends, fostering valuable connections and knowledge exchange."

            },
            {
                image: '/company/events/wrapped-up-event-1.png',
                title: 'TechCon SoCal 2024',
                description: "Delve into the standout moments from Fiber Mountain's participation at TechCon SoCal! From our engaging exhibition to insightful presentations by our team, we connected with industry leaders, shared valuable insights, and demonstrated our latest technological innovations."

            },
            {
                image: '/company/events/wrapped-up-event-2.png',
                title: 'BICSI Winter Conference and Exhibition 2023',
                description: "Fiber Mountain had an impactful presence at BICSI Winter Conference 2024! Our exhibition and CEO's insightful speaking session were key moments where we connected with industry leaders, shared knowledge, and showcased our cutting-edge products."
            },
        ]
    }
    return (
        <>
            <div>
                <Helmet>
                    <title>Fiber Mountain | Events</title>
                    <meta name="description" content="Discover where Fiber Mountain will be next and where we've been in the world of data center technology events." />
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div className="events-wrapper bg-white text-white">
                <div className="hero position-relative">
                    {/* Nav Start */}
                    <Nav />
                    {/* Nav End */}
                    <div className="hero-content hero-bg bg-blue d-flex justify-content-center align-items-center flex-column position-relative">
                        <h1 className="poppins-semi-bold text-white text-center hero-heading">Events</h1>
                        <h6 className="dmsans-regular text-white hero-subheading ">Hear from our experts at events all over the world</h6>
                    </div>
                </div>
                <div className="container">
                    {/* Upcoming Events Start */}
                    <div className="upcoming-events-container">
                        <div className="d-flex justify-content-center align-items-center flex-column">
                            <h2 className="poppins-regular text-black upcoming-events-heading">Upcoming Events</h2>
                        </div>
                        <div className="slider-container events-slider">
                            <Slider {...settings}>
                                {eventsAssets.upcomingEvents.map((value, index) => {
                                    return (
                                        <div class="d-flex flex-column event-container position-relative">
                                            <div>
                                                <picture className="w-100">
                                                    <source media="(max-width: 600px)" srcSet={value.backgroundImageMobile} />
                                                    <img className="w-100" id={value.index} src={value.backgroundImage} />
                                                </picture>

                                            </div>
                                            <div className="content">
                                                <h6 className="dmsans-regular booth-container">{value.booth}</h6>
                                                <h6 className="dmsans-regular date font-weight-700">{value.date}</h6>
                                                <h6 className="dmsans-regular location font-weight-700">{value.location}</h6>
                                                <h3 className="poppins-semi-bold font-weight-600 event-title">{value.title}</h3>
                                                <p className="dmsans-regular event-description">{value.description}</p>
                                                <div className="d-flex btn-flex-container">
                                                    <Link to="/contact-us" className="btn primary-btn bg-primary-color text-white border-primary-color">
                                                        Schedule a meeting
                                                    </Link>
                                                    <Link to={value.link} target="_blank" className="btn secondary-btn secondary-light-border text-white border-primary-color">
                                                        Learn more
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                    </div>
                    {/* Upcoming Events End */}

                    {/* Wrapped Up Events Start */}
                    <div className="wrapped-up-events">
                        <div className="d-flex justify-content-center align-items-center flex-column">
                            <h2 className="poppins-regular text-black wrapped-up-heading">Just Wrapped Up: Recent Events</h2>
                        </div>
                        <div class="d-flex flex-row wrapped-up-container">
                            {eventsAssets.wrappedUpEvents.map((value, index) => {
                                return (
                                    <div className="flex-element">
                                        <div>
                                            <img className="w-100" src={value.image} alt={value.title} />
                                        </div>
                                        <div className="content bg-pink text-black">
                                            <h4 className="poppins-semi-bold font-weight-600 slide-title">{value.title}</h4>
                                            <p className="dmsans-regular">{value.description}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {/* Wrapped Up Events End */}

                    {/* Wrapped Up Events Start */}
                    <div className="wrapped-up-events">
                        <div className="d-flex justify-content-center align-items-center flex-column">
                            <h2 className="poppins-regular text-black wrapped-up-heading">Events Gallery</h2>
                        </div>
                        <img className="w-100" src={eventsAssets.eventGallery} />
                    </div>
                    {/* Wrapped Up Events End */}

                </div>
                {/* Footer Start */}
                <Footer />
                {/* Footer End */}
            </div >
        </>
    );
}