import Footer from "../footer/footer";
import Nav from "../nav/nav";
import "./sitemap.scss";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

export default function PrivacyPolicy() {
    // Define constants for privacy policy content
    const PRIVACY_DIVIDER = '/company/privacy-divider.png';
    const links = {
        productsLinks: [
            {
                title: 'All Products',
                url: '/all-products',

            },
            {
                title: 'AllPath® Director',
                url: '/products/apd',
            },
            {
                title: 'Fiber Optic Cables',
                url: '/products/fiber-optic-cables',
            },
            {
                title: 'J-Series',
                url: '/products/j-series',
            },
            {
                title: 'OPX®',
                url: '/products/opx',
            },
            {
                title: 'Sensus™',
                url: '/products/sensus',
            }
        ],
        solutionLinks: [
            {
                title: 'Our Solutions',
                url: '/all-solutions',
            },
            {
                title: 'Automated Documentation',
                url: '/solutions/automated-documentation',
            },
            {
                title: 'Capacity Management',
                url: '/solutions/capacity-management',
            },
            {
                title: 'Data Center Colocation',
                url: '/solutions/datacenter-colocation',
            },
            {
                title: 'Layer One Diagnostics',
                url: '/solutions/layer-one-diagnostics',
            },
            {
                title: 'Physical Layer Security',
                url: '/solutions/physical-layer-security',
            },
            {
                title: 'Universal Breakout',
                url: '/solutions/universal-breakout',
            },
        ],
        companyLinks: [
            {
                title: 'About Us',
                url: '/about-us',
            },
            {
                title: 'Life at Green Lambda',
                url: '/glc-team',
            },
            {
                title: 'Contact Us',
                url: '/contact-us',
            },
            {
                title: 'Privacy Policy',
                url: '/privacy-policy',
            },
            {
                title: 'EULA',
                url: '/eula',
            },
        ],
        resourcesLinks: [
            {
                title: 'All Resources',
                url: '/resources',
            },
            {
                title: 'Whitepapers',
                url: '/resources/#whitepapers',
            },
            {
                title: 'Solution Briefs',
                url: '/resources/#solution-briefs',
            },

            {
                title: 'Datasheets',
                url: '/resources/#datasheets',
            },
            {
                title: 'E Books',
                url: '/resources/#ebooks',
            },
            {
                title: 'Videos',
                url: '/resources/#videos',
            },
        ],
    }

    return (
        <>
            <div>
                <Helmet>
                    <title>Sitemap | Green Lambda Corporation</title>
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div className="sitemap-wrapper bg-blue">
                <div className="hero position-relative">
                    <div className="privacy-policy-right-gradient"></div>
                    <div className="privacy-policy-left-gradient"></div>
                    {/* Nav Start */}
                    <Nav />
                    {/* Nav End */}
                    <div className="container">
                        <h1 className="nexa-bold text-white text-center">GLC <span className="text-primary-green">Sitemap</span></h1>
                        <img className="w-100 divider-margin" src={PRIVACY_DIVIDER} />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12 mx-auto">
                                <div className="row">
                                    <div className="col-lg-7 col-sm-7">
                                        <h3 className="nexa-bold text-primary-green home-list"><Link className="text-url" to="/">Home</Link></h3>
                                        <h3 className="nexa-bold text-primary-green"><Link className="text-url" to="/all-products">Products</Link></h3>
                                        <ul className="sitemap-list">
                                            {links.productsLinks.map((value, index) => {
                                                return (
                                                    <li className="dmsans-regular text-white"><h5><Link className="sitemap-link text-secondary-white" to={value.url}>{value.title} <i className="fa-solid fa-arrow-right mx-2"></i></Link></h5></li>
                                                )
                                            })}
                                        </ul>
                                        <h3 className="nexa-bold text-primary-green"><Link className="text-url" to="/">Solutions</Link></h3>
                                        <ul className="sitemap-list">
                                            {links.solutionLinks.map((value, index) => {
                                                return (
                                                    <li className="dmsans-regular text-white"><h5><Link className="sitemap-link text-secondary-white" to={value.url}>{value.title} <i className="fa-solid fa-arrow-right mx-2"></i></Link></h5></li>
                                                )
                                            })}
                                        </ul>



                                    </div>
                                    <div className="col-lg-5 col-sm-5">
                                        <h3 className="nexa-bold text-primary-green">Resources</h3>
                                        <ul className="sitemap-list">
                                            {links.resourcesLinks.map((value, index) => {
                                                return (
                                                    <li className="dmsans-regular text-white"><h5><Link className="sitemap-link text-secondary-white" to={value.url}>{value.title}<i className="fa-solid fa-arrow-right mx-2"></i></Link></h5></li>
                                                )
                                            })}
                                        </ul>

                                        <h3 className="nexa-bold text-primary-green">Company</h3>
                                        <ul className="sitemap-list">
                                            {links.companyLinks.map((value, index) => {
                                                return (
                                                    <li className="dmsans-regular text-white"><h5><Link className="sitemap-link text-secondary-white" to={value.url}>{value.title}<i className="fa-solid fa-arrow-right mx-2"></i></Link></h5></li>
                                                )
                                            })}
                                        </ul>
                                    </div>

                                </div>


                            </div>


                        </div>
                    </div>

                </div>
                {/* Capacity Management Hero Section Ends */}
                {/* Footer Start */}
                <Footer />
                {/* Footer End */}
            </div >
        </>
    );
}