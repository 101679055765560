import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import logo from './logo.svg';
import './App.css';
import './sassStyles/_global.scss';
import Home from './components/home/home';
import AllProducts from './components/all-products/all-products';
import EnterpriseSolutions from './components/all-solutions/enterprise-solutions';
import ComingSoon from './components/coming-soon/coming-soon';
import Events from './components/events/events';
import AboutUs from './components/about-us/about-us';
import ContactUs from './components/contact-us/contact-us';
import Resources from './components/resources/resources';
import SensusStarling from './components/sensus/sensus-starling';
import SensusVision from './components/sensus/sensus-vision';
import ApdVision from './components/apd/apd-vision';
import ApdStarling from './components/apd/apd-starling';
import NotFound from './components/error-404/error-404';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from './components/scroll-to-top/scroll-to-top';
import PrivacyPolicy from './components/privacy-policy/privacy-policy';
import EULA from './components/EULA/EULA';
import Sitemap from './components/sitemap/sitemap';
function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path='*' element={<NotFound />} />
          <Route exact path="/" element={<Home />} />

          {/* Products Routes Start */}
          <Route exact path="/all-products" element={<AllProducts />} />
          <Route exact path="/products/sensus-starling" element={<SensusStarling />} />
          <Route exact path="/products/sensus-vision" element={<SensusVision />} />
          <Route exact path="/products/apd-starling" element={<ApdStarling />} />
          <Route exact path="/products/apd-vision" element={<ApdVision />} />
          <Route exact path="/enterprise-solutions" element={<EnterpriseSolutions />} />
          <Route exact path="/coming-soon" element={<ComingSoon />} />
          {/* Products Routes End */}

          {/* Company Routes Start */}
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/events" element={<Events />} />
          <Route exact path="/fm-resources" element={<Resources />} />
          {/* Company Routes End */}

          {/* Other Pages Start */}
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/eula" element={<EULA />} />
          <Route exaxt path="/sitemap" element={<Sitemap/>} />
          {/* Other Pages End */}
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}
export default App;
