import Footer from "../footer/footer";
import Nav from "../nav/nav";
import MoreProducts from "../more-products/more-products";
import "./sensus-vision.scss";
import { Link } from "react-router-dom";
import ThreeSixtyView from "../360-view/360-view";
import CrossSectionView from "../cross-section-view/cross-section-view";
import VideoModal from "../video-modal/video-modal";
import { Helmet } from 'react-helmet';

export default function Sensus() {

    const Sensus = {
        videoThumbnail: '/products/sensus-assets/vision-video-thumbnail.png',
        title: 'How Does the Sensus™ Work?',
        sensusCallouts: '/products/sensus-assets/sensus-vision-callouts.png',
        sensus360Btn: '/products/sensus-assets/360-view-btn.png',
        videoLink: 'pHAHG-nUk8o?si=bQEKbznVSswgsz-C',
        frontCover: '/products/sensus-assets/front-cover.png',
        compactCover: '/products/sensus-assets/compact-cover.png',
        rearFullCover: '/products/sensus-assets/rear-full-cover.png',
        mountingBrackets: '/products/sensus-assets/mounting-brackets.png',
        moreProductHeader: '/products/sensus-assets/more-product-header.png',
        featureHeading: 'Sensus™',
        divider: '/products/product-separator.png',
        datasheet: '/resources/datasheets/Sensus.pdf',
        sensusFeatureTAAandJTIC: '/products/sensus-assets/sensus-feature-TAA-JTIC.png',
        sensusFeatureFIPS: '/products/sensus-assets/sensus-feature-FIPS.png',
        sensusFeatureDeviceVerification: '/products/sensus-assets/sensus-feature-device-verification.png',
        sensusFeatureTriLeds: '/products/sensus-assets/sensus-feature-tri-led.png',
        sensusFeatureNetworkDiagnostics: '/products/sensus-assets/sensus-feature-vision-network-diagnostics.png',
        sensusFeaturePassivePatchPanel: '/products/sensus-assets/sensus-feature-passive-patch-panel.png',
        sensusFeatureSwapablePatchingModules: '/products/sensus-assets/sensus-feature-swapable-patching-modules.png',
        sensusFeatureLowInsertionReturnLoss: '/products/sensus-assets/sensus-feature-low-insertion-return-loss.png',
        options: {
            images: '/products/sensus-assets/sensus-360-view/',
            imgCount: 60,
            modalName: 'sensus360',
            title: '<span class="text-black">Sensus™</span> Vision Patch Panel',
            playSpeed: 20,
            imgWidth: 650,
            imgHeight: 434,
            isReverse: false
        },
        crossSectionOptions: {
            title: '<span class="text-black">Sensus™</span> Vision Patch Panel',
            modalName: 'CrossSectionView',
            image: '/products/sensus-assets/sensus-feature-passive-patch-panel.png'
        },
        usecases: [
            {
                title: "Secured Networking Capacity Trackingt "
            },
            {
                title: "Secured Networking for Federal Applications"
            },
            {
                title: "Cross Hall</br> Connectivity"
            },
            {
                title: "Top of the Rack</br> Patching Capability"
            },
            {
                title: "Port</br> Replication"
            },
            {
                title: "QSFP</br> Universal Breakout"
            }

        ],

        products: [
            {
                image: '/products/more-products/apd-vision.png',
                title: 'AllPath® Director Vision ',
                oneLiner: 'Network Management Software for Mission-Critical Environments',
                information: 'JTIC certified, federal network management software that adds a robust layer of security while optimizing the management of patch panels & physical connectivity. It automates network documentation, tracks MACs, and reduces errors, providing network-wide visibility.',
                url: '/products/apd-vision',
            },
            {
                image: '/products/more-products/apd-starling.png',
                title: 'AllPath® Director Starling',
                oneLiner: 'Network Management Software for Mission-Critical Environments',
                information: 'AllPath® Director Starling is an enterprise network management software that optimizes the management of patch panels & physical connectivity. It automates network documentation, tracks network changes, and reduces errors, providing network-wide visibility while increasing efficiency and lowering operational costs.',
                url: '/products/apd-starling',
            },
            {
                image: '/products/more-products/sensus-starling.png',
                title: 'Sensus™ Starling Patch Panel',
                oneLiner: 'Intelligent Patch Panel for Enterprise Networks',
                information: 'Sensus™ Starling is an intelligent patch panel that is designed to secure and automate fiber connectivity management with intelligent detection optimizing data center infrastructure.',
                url: '/products/sensus-starling',
            },
        ],
    }
    const modalName = Sensus.title.replace(/[^a-zA-Z0-9]+/g, '-').replace(/^-+|-+$/g, '').replace(/\s+/g, '-');

    return (
        <>
            <div>
                <Helmet>
                    <title>Fiber Mountain | Sensus ™ Vision Patch Panel </title>
                    <meta name="description" content="TAA Compliant and JTIC certified managed patch panel with an added layer of security suitable for mission-critical environments." />
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div className="sensus-wrapper">
                {/* Nav Section Start */}
                <Nav />
                {/* Nav Section End */}

                {/* Hero Section Start */}
                <div className="hero">
                    <div className="hero-content hero-bg bg-blue d-flex justify-content-center align-items-center flex-column position-relative">
                        <div className="hero-heading-bg"><h1 className="poppins-semi-bold text-white text-center hero-heading">Sensus™ Vision Patch Panel</h1></div>
                        <h6 className="dmsans-regular text-white hero-subheading text-center">More Than Just a Patch Panel for Government and Defense Operations</h6>
                    </div>
                </div>
                {/* Hero Section End */}


                <div className="bg-white">
                    <div className="">
                        <div className="container">
                            {/* Product Diagram Section Start */}
                            <div className="text-center text-white sensus-diagram-heading">
                                <h4 className="dmsans-regular text-black">Sensus™ Vision is a managed patch panel that secures and automates fiber connectivity management with intelligent change detection suitable for mission-critical environments.</h4>
                            </div>
                            <div className="row px-4 px-lg-0">
                                <div className="col-lg-12 mx-auto">
                                    <div className="row mb-3">
                                        <div className="offset-lg-1 col-10 text-center position-relative">
                                            <img className="w-100" src={Sensus.sensusCallouts} />
                                            <img id="360-btn" data-bs-toggle="modal" data-bs-target={'#' + Sensus.options.modalName} className="view-btn-width cursor-pointer" src={Sensus.sensus360Btn} />
                                            <ThreeSixtyView options={Sensus.options} />
                                        </div>
                                    </div>


                                    <div className="col-12 text-center mt-sm-0 diagram-btn-container">
                                        <CrossSectionView options={Sensus.crossSectionOptions} />
                                        <Link data-bs-toggle="modal" data-bs-target={'#' + Sensus.crossSectionOptions.modalName} className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color mt-2 me-sm-4">CROSS-SECTION VIEW</Link>
                                        <Link to={Sensus.datasheet} target="_blank" className="btn secondary-btn dmsans-regular text-white mt-2">DATASHEET</Link>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Sensus Diagram Section End */}

                        {/* Sensus Video Start */}
                        <div className="col-lg-12 container sensus-video-section text-center">
                            <h2 className="poppins-regular text-black font-weight-400">Discover the Future of Connectivity with Sensus™</h2>

                            <img className="w-100 cursor-pointer" src={Sensus.videoThumbnail} data-bs-target={'#' + modalName} data-bs-toggle="modal" />
                            <VideoModal options={{ videoLink: Sensus.videoLink, modalName }} />
                        </div>
                        {/* Sensus Video end */}

                        {/* Sensus Features Start */}
                        <div className="col-lg-12 container sensus-features-section">
                            <div className="text-center">
                                <h2 className="poppins-regular text-black font-weight-400 text-center">Product Features</h2>
                            </div>
                            <div className="row text-black feature-element">
                                <div className="col-lg-7 col-sm-6 m-auto">
                                    <h3 className="poppins-semi-bold"><span className="text-primary-red">TAA & JTIC</span> Certified</h3>
                                    <h6 className="dmsans-regular">
                                    Sensus™ Vision is meticulously engineered to meet rigorous standards with TAA compliance and JTIC certification. This ensures it meets federal requirements and delivers exceptional performance and seamless integration within your network.
                                    </h6>
                                </div>
                                <div className="col-lg-5 col-sm-6 text-center">
                                    <img className="my-auto w-100" src={Sensus.sensusFeatureTAAandJTIC} />
                                </div>
                            </div>
                            <div className="row text-black feature-element">
                                <div className="col-lg-5 col-sm-6 text-center">
                                    <img className="my-auto image-width" src={Sensus.sensusFeatureFIPS} />
                                </div>
                                <div className="col-lg-7 col-sm-6 m-auto text-right">
                                    <h3 className="poppins-semi-bold"><span className="text-primary-red">FIPS</span> Integrated</h3>
                                    <h6 className="dmsans-regular margin-left-auto">
                                    Equipped with a FIPS module, our patch panel offers robust security and compliance with federal encryption standards, safeguarding your network infrastructure.
                                    </h6>
                                </div>
                            </div>
                            <div className="row text-black feature-element">
                                <div className="col-lg-7 col-sm-6 m-auto">
                                    <h3 className="poppins-semi-bold"><span className="text-primary-red">Two-step Device </span>Access Verification</h3>
                                    <h6 className="dmsans-regular">
                                    Sensus™ Vision patch panel enhances security with two-step verification for access, ensuring that only authorized personnel can manage your network infrastructure.
                                    </h6>
                                </div>
                                <div className="col-lg-5 col-sm-6 text-center">
                                    <img className="my-auto w-100" src={Sensus.sensusFeatureDeviceVerification} />
                                </div>
                            </div>
                            <div className="row text-black feature-element">
                                <div className="col-lg-5 col-sm-6 text-center d-flex">
                                    <img className="my-auto image-width" src={Sensus.sensusFeatureTriLeds} />
                                </div>
                                <div className="col-lg-7 col-sm-6 m-auto text-right">
                                    <h3 className="poppins-semi-bold">Tri-color Interactive <span className="text-primary-red">LEDs</span></h3>
                                    <h6 className="dmsans-regular margin-left-auto">
                                    Equipped with tri-color interactive LEDs, the Sensus™ Vision patch panel aids technicians with clear visual cues, ensuring swift troubleshooting in critical government operations and high-stakes defense environments.
                                    </h6>
                                </div>
                            </div>
                            <div className="row text-black feature-element">
                                <div className="col-lg-7 col-sm-6 m-auto">
                                    <h3 className="poppins-semi-bold"><span className="text-primary-red">Network Diagnostics &</span> Health Checks</h3>
                                    <h6 className="dmsans-regular">
                                    Health checks that make sure your devices are always up and running. Robust diagnostic assessments and performance evaluations proactively monitor devices, identify any potential issues, and promptly alert network administrators in case of abnormalities or failures. 
                                    </h6>
                                </div>
                                <div className="col-lg-5 col-sm-6 text-center">
                                    <img className="my-auto image-width-led" src={Sensus.sensusFeatureNetworkDiagnostics} />
                                </div>
                            </div>
                            <div className="row text-black feature-element">
                                <div className="col-lg-5 col-sm-6 text-center">
                                    <img className="my-auto image-width-80" src={Sensus.sensusFeaturePassivePatchPanel} />
                                </div>
                                <div className="col-lg-7 col-sm-6 m-auto text-right">
                                    <h3 className="poppins-semi-bold"><span className="text-primary-red">100%</span> Passive Patch Panel</h3>
                                    <h6 className="dmsans-regular margin-left-auto">
                                    Sensus™ Vision is engineered to ensure that your critical operations remain secure and reliable under any circumstances. Even if the management module loses power, user traffic remains unaffected, guaranteeing seamless operation and uninterrupted service continuity.
                                    </h6>
                                </div>
                            </div>
                            <div className="row text-black feature-element">
                                <div className="col-lg-7 col-sm-6 m-auto">
                                    <h3 className="poppins-semi-bold"><span className="text-primary-red">Swapable</span> Patching Modules</h3>
                                    <h6 className="dmsans-regular">
                                    Sensus™ Vision patching modules are hot-swappable, supporting single-mode and multimode fiber with UPC or APC connectors. Conveniently located on either side of the management module, they offer easy access and maintenance.
                                    </h6>
                                </div>
                                <div className="col-lg-5 col-sm-6 text-center d-flex">
                                    <img className="my-auto image-width-swapable-patch" src={Sensus.sensusFeatureSwapablePatchingModules} />
                                </div>
                            </div>
                            <div className="row text-black feature-element">
                                <div className="col-lg-5 col-sm-6 text-center">
                                    <img className="my-auto image-width-80" src={Sensus.sensusFeatureLowInsertionReturnLoss} />
                                </div>
                                <div className="col-lg-7 col-sm-6 m-auto text-right">
                                    <h3 className="poppins-semi-bold">Low Insertion and <span className="text-primary-red">Return Loss</span></h3>
                                    <h6 className="dmsans-regular margin-left-auto">
                                    Each port on the patching panel is equipped with sensors to detect cable connections and collect data from ICID-enabled cables, ensuring high-quality performance and minimizing insertion and return loss.
                                    </h6>
                                </div>
                            </div>
                        </div>
                        {/* Sensus Features End */}


                        {/* Sensus Usecase Start */}
                        <div className="col-lg-12 container sensus-usecase-section text-center">
                            <h2 className="poppins-regular text-black font-weight-400">What Sensus™ Vision Can Do For You?</h2>
                            <div className="row">
                                {Sensus.usecases.map((value, index) => {
                                    return (
                                        <div className="col-md-4">
                                            <div className="bg-purple use-case-wrapper">
                                                <h4 className="poppins-regular text-white font-weight-600" dangerouslySetInnerHTML={{ __html: value.title }}></h4>
                                            </div>

                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                        {/* Sensus Usecase end */}

                        {/* Sensus Accessories Section Start */}
                        <div className="container">
                            <div className="sensus-accessories-section">
                                <h2 className="poppins-regular text-center text-black">Accessorize your Sensus™ </h2>
                                <div className="accessories-container">
                                    <div className="row">
                                        <div className="col-lg-8 rack-container pe-lg-2 pe-0 mb-lg-0 mb-5">
                                            <div className="rack-row bg-pink row">
                                                <div className="col-lg-4">
                                                    <h3 className="text-black poppins-semi-bold">
                                                        2-Rack Unit
                                                        <div className="text-black">Front Cover</div></h3>
                                                    <p className="dmsans-regular text-black">Also available in 4RU form factor</p>
                                                </div>
                                                <div className="col-lg-8 text-center">
                                                    <img className="mw-100" src={Sensus.frontCover} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="compact-cover-row bg-pink row">
                                                <img src={Sensus.compactCover} />
                                                <div className="compact-details">
                                                    <h4 className="text-black poppins-semi-bold">
                                                        2-Rack Unit
                                                        <div className="text-black poppins-semi-bold">Rear Management</div>
                                                        <div className="text-black poppins-semi-bold">Tray - Compact</div></h4>
                                                    <p className="dmsans-regular text-black">Also available in 4RU form factor</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-lg-4 mt-5">
                                        <div className="col-lg-4">
                                            <div className="rear-cover-row bg-pink row">
                                                <div className="rear-details">
                                                    <h4 className="text-black poppins-semi-bold">

                                                        2-Rack Unit
                                                        <div className="text-black">Rear Full Cover</div></h4>
                                                    <p className="dmsans-regular text-black">Also available in 4RU form factor</p>
                                                </div>
                                                <div>
                                                    <img className="mw-100 h-auto" src={Sensus.rearFullCover} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 brackets-container mt-lg-0 mt-5 ps-lg-2 ps-0">
                                            <div className="mounting-brackets-row bg-pink row">
                                                <div className="col-lg-4">
                                                    <h3 className="text-black poppins-semi-bold">
                                                        Mounting
                                                        <div className="text-black">Brackets</div></h3>
                                                    <p className="dmsans-regular text-black">Available in
                                                        <div>4RU and 2RU form factor </div>
                                                    </p>
                                                </div>
                                                <div className="col-lg-8">
                                                    <img className="mw-100" src={Sensus.mountingBrackets} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Sensus Accessories Section End */}

                    </div>
                </div>
                {/* Product Diagram Section Start */}
                 {/* More Products Section Start */}
                 <MoreProducts products={Sensus.products} />
                        {/* More Product Section End */}
                <Footer />
            </div>
        </>
    );
}