import Footer from "../footer/footer";
import Nav from "../nav/nav";
import "./resources.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useLocation } from "react-router-dom";
// import Blog from "../blog/blog";
import VideoModal from "../video-modal/video-modal";
import { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import AnimatedText from "../animated-text/animated-text";

export default function Resources() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        touchMove: false,
        lazyLoad: 'progressive',
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
        ]
    }
    const Resources = {
        glcFeaturedContent: [
            {
                label: 'E-Book',
                title: 'Physical Layer 2.0 Secure & Intelligent',
                info: 'Physical layer is the building block of any and all network activity. Effectively, it is the most integral component of any data center. In order to maximize data center agility, we need to re-imagine the physical layer with integrated security and intelligence.',
                link: '/resources/ebooks/Fiber-Mountain-Physical-Layer-2.0.pdf'

            },
            {
                label: 'White Paper',
                title: 'Sensus™: Digital Transformation for the Physical Layer',
                info: 'People and their devices are getting more connected, big data is getting bigger and datacenters need more and more cable connections. We need a better way to manage them! That’s where Sensus™ and Fiber Mountain solutions come in.',
                link: '/resources/whitepapers/Fiber-Mountain-Sensus-Digital-Transformation-Physical-Layer.pdf'

            },
            {
                label: 'Datasheet',
                title: 'AllPath<sup>®</sup> Director Starling - Network Management Software',
                info: 'Central to the Fiber Mountain solution, AllPath® Director Starling is an advanced enterprise network management software. It automates network documentation and streamlines the management of patch panels and physical connectivity.',
                link: '/resources/datasheets/AllPath-Director.pdf'

            }
        ],
        glcWhitepapers: [
            {
                title: "Fiber Mountain's Zero Trust Managed Patching Solution?",
                icon: '/company/resources/apd-whitepaper.png',
                info: "Fiber Mountain's AI-managed patching solutions with zero-trust security marks a significant advancement in data center security. This combination provides groundbreaking possibilities, it can tackle conventional network weaknesses, enhance operational efficiency, and fortify overall security measures.",
                pdfLink: '/resources/whitepapers/Fiber-Mountain-Zero-Trust-Managed-Patching-Solution.pdf'
            },
            {
                title: '<span className="text-primary-green">Sensus™</span>: Digital Transformation for the Physical Layer ',
                icon: '/company/resources/sensus-whitepaper.png',
                info: "People and their devices are getting more connected, big data is getting bigger and datacenters need more and more cable connections. We need a better way to manage them! That’s where Sensus™ and Fiber Mountain solutions come in.",
                pdfLink: '/resources/whitepapers/Fiber-Mountain-Sensus-Digital-Transformation-Physical-Layer.pdf'
            },
            {
                title: 'What is the Fiber Mountain Solution?',
                icon: '/company/resources/fibermountain-solution.png',
                info: "Re-configuring the physical layer connections can be a challenging task from a labor perspective, especially with poor documentation. The Fiber Mountain solution was conceived to address these issues and provide additional operational benefits to help reduce costs.",
                pdfLink: '/resources/whitepapers/Fiber-Mountain-Solution.pdf'
            }

        ],
        glcDataSheets: [
            {
                title: 'AllPath<sup>®</sup> Director - Network Management Software',
                icon: '/company/resources/apd.png',
                info: "Central to the Fiber Mountain solution, AllPath® Director Starling is an advanced enterprise network management software. It automates network documentation and streamlines the management of patch panels and physical connectivity, enhancing data center efficiency and control.",
                pdfLink: '/resources/datasheets/AllPath-Director.pdf'
            },
            {
                title: 'Sensus™ Premium Fiber Patch Panel',
                icon: '/company/resources/sensus.png',
                info: "Fiber Mountain’s Sensus™ intelligent panels aggregate and break out fiber connectivity from duplex LC cables to high density 8-fiber, 12- fiber and 24-fiber MPO trunks. A Sensus™ chassis incorporates three modules: one management module with an integrated four-inch LCD screen, and two interchangeable patching modules.",
                pdfLink: '/resources/datasheets/Sensus.pdf'
            },
            {
                title: 'J-Series: High Density MPO to Duplex LC Breakout',
                icon: '/company/resources/j-series.png',
                info: "Fiber Mountain’s J-Series breakout panels provide all front-facing connectivity. The panel is modular with room for 8 MPO-to-LC cassettes in a 1 RU frame. Cassettes include Type A and Type B mapped QSFP breakout for 40G, 100G and 400G applications, and are available with Single Mode and Multimode fiber.",
                pdfLink: '/resources/datasheets/J-Series.pdf'
            },
            {
                title: 'Premium Fiber Optic<br/>Cables',
                icon: '/company/resources/fiber-optic.png',
                info: "Fiber Mountain’s pre-terminated multi-fiber cables are suitable for all data center cabling needs. Capable of handling high-speed 10G, 25G, 40G, or 100G applications, these ultra high-quality fiber optic cables coupled with Green Lambda's OPX® cross-connects and L Series Fiber Port Aggregators to provide superb performance.",
                pdfLink: '/resources/datasheets/Fiber-Optic-Cables.pdf'
            },
            {
                title: 'ICID<sup>®</sup> Cables NFC<br/> Capability',
                icon: '/company/resources/cables.png',
                info: "Fiber Mountain’s pre-terminated multi-fiber ICID® (Intelligent Connection Identification) cables couple with Fiber Mountain's products to provide a managed physical layer. ICID-enabled cables feature NFC/RFID tags programmed with data relevant to the cable, such as fiber type, manufacturing information, and serial number.",
                pdfLink: '/resources/datasheets/ICID-Clips.pdf'
            },
            {
                title: 'Sensus™ Front Facing Patch Panel',
                icon: '/company/resources/sensus.png',
                info: "Fiber Mountain’s Sensus™ intelligent panels aggregate and break out fiber connectivity from duplex LC cables to high density 8-fiber, 12- fiber and 24-fiber MPO trunks. A Sensus™ chassis incorporates three modules: one management module with an integrated four-inch LCD screen, and two interchangeable patching modules.",
                pdfLink: '/resources/datasheets/Sensus-Front-Facing-Patch-Panel.pdf'
            },
        ],
        glcSolutionBriefs: [
            {
                title: 'Automated Documentation',
                icon: '/company/resources/automated-documentation.png',
                info: "Data centers using GLC solutions enjoy automated documentation of the real network topology, based on both ICID® and more general device detection. This makes it easy to see end-to-end connections and introduces flexibility to group and arrange connections as needed.",
                pdfLink: '/glc-resources/solutionbriefs/Automated Documentation.pdf'
            },
            {
                title: 'Capacity Management',
                icon: '/company/resources/capacity-management.png',
                info: "The GLC solution is designed to help organizations manage their physical layer infrastructure efficiently. It offers a wide range of planning actions that ensure that the infrastructure has sufficient resources to maximize its potential activities and production output, regardless of the conditions.",
                pdfLink: '/glc-resources/solutionbriefs/Capacity Management.pdf'
            },
            {
                title: 'Data Center Colocation',
                icon: '/company/resources/data-center-colocation.png',
                info: "Whether you’re running a large colocation data center or you’re a tenant within one, the Green Lambda Colocation solution improves physical layer service request times and minimizes human errors in MACs.",
                pdfLink: '/glc-resources/solutionbriefs/Data Center Colocation.pdf'
            },
            {
                title: 'Layer One Diagnostics',
                icon: '/company/resources/layer-one-diagnostics.png',
                info: "Health checks that make sure your Sensus™ devices are always up and running. Regular diagnostic assessments and performance evaluations proactively monitor devices, identifying any potential issues, and promptly alerting network administrators in case of abnormalities or failures.",
                pdfLink: '/glc-resources/solutionbriefs/Layer One Diagnostics.pdf'
            },
            {
                title: 'Physical Layer Security',
                icon: '/company/resources/physical-layer-security.png',
                info: "Physical layer security is driven by real-time documentation, comprehensive event logs that provide insight into every physical layer change, and the ability to set off actions in response to network changes.",
                pdfLink: '/glc-resources/solutionbriefs/Physical Layer Security.pdf'
            },
            {
                title: 'Universal Breakout',
                icon: '/company/resources/universal-breakout.png',
                info: "Universal breakout devices for high density switch ports provide easy scalability and flexibility in network configurations. Adapt and expand your network infrastructure, ensuring smooth and efficient operations.",
                pdfLink: '/glc-resources/solutionbriefs/Universal Breakout.pdf'
            },
        ],
        glcEBooks: [
            {
                title: 'Physical Layer 2.0 | Secure and Intelligent',
                icon: '/company/resources/physical-layer.svg',
                info: "Physical layer is the building block of any and all network activity. Effectively, it is the most integral component of any data center. In order to maximize data center agility, we need to re-imagine the physical layer with integrated security and intelligence.",
                pdfLink: '/resources/ebooks/Fiber-Mountain-Physical-Layer-2.0.pdf'
            },
            {
                title: 'The Digital Transformation Road Trip',
                icon: '/company/resources/digital-transformation.svg',
                info: "Everybody is talking about Digital Transformation, but it`s difficult to actually move ahead. If you`re having trouble finding your way through the Infrastructure Bottleneck or overcoming resistance from Organizational Silos, you need to read The Digital Transformation Road Trip.",
                pdfLink: '/resources/ebooks/Fiber-Mountain-Digital-Transformation-Road-Map.pdf'
            },
        ],
        glcVideos: [
            {
                title: 'Sensus™– The Intelligent Patch Panel Overview',
                thumbnail: '/company/resources/thumbnail-9.png',
                videoLink: 'pHAHG-nUk8o?si=gfGq7a0B9B9_Ybnw'
            },
            {
                title: 'ICID Enabled Cables',
                thumbnail: '/company/resources/thumbnail-6.png',
                videoLink: 'aNwq2Hna56g?si=ePXMfx2hoDmV4JQp'

            },
            {
                title: 'Automated Documentation Real Time Data Center Visibility',
                thumbnail: '/company/resources/thumbnail-2.png',
                videoLink: 'qQkfuo_GIA8?si=OuEWTZL0zUYjTrY9'
            },
            {
                title: 'Data Center Colocation - Guiding Hands',
                thumbnail: '/company/resources/thumbnail-3.png',
                videoLink: 'PrvfkxLOZW8?si=li1TAU8c4fl7XvHy'
            },
            {
                title: 'J Series Patch Panel – A Quick Tutorial',
                thumbnail: '/company/resources/thumbnail-4.png',
                videoLink: 'oxKC3s24muc?si=IgGnWR99mzbwBDzJ'
            },
            {
                title: 'Sensus™– Modules Breakdown and Explanation',
                thumbnail: '/company/resources/thumbnail-8.png',
                videoLink: 'OS4UPDPnFvI?si=QXW3tF3xKIm5bKGC'
            },

            {
                title: 'What Makes a Quality Fiber Cable',
                thumbnail: '/company/resources/thumbnail-7.png',
                videoLink: 'yX8L9jGWuBQ?si=APm_pvdSqnPbpeoS'
            }

        ]
    }
    const navigate = useNavigate();

    const [activePill, setActivePill] = useState(0);
    const pillsRef = useRef(null);
    const pillRefContainer = useRef([]);
    const colorIndicatorRef = useRef(null);
    const { hash } = window.location;

    const pills = [
        { label: 'White Papers', id: 'whitepapers' },
        { label: 'Datasheets', id: 'datasheets' },
        // { label: 'Solution Briefs', id: 'solution-briefs' },
        { label: 'E-Books', id: 'ebooks' },
        { label: 'Videos', id: 'videos' },
    ];

    useEffect(() => {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 600) {
            return;
        }

        if (pillsRef.current && colorIndicatorRef.current) {
            const pillsContainer = pillsRef.current;
            const pillsElements = Array.from(pillsContainer.getElementsByClassName('nav-item'));
            const colorIndicator = colorIndicatorRef.current;
            const activePillElement = pillsElements[activePill];

            const pillHeight = activePillElement.offsetHeight;

            let transformX = +parseFloat(window.getComputedStyle(pillsElements[0]).marginLeft);
            for (let i = 0; i < activePill; i++) {
                const pill = pillsElements[i];
                const pillWidth = pill.offsetWidth;
                const pillMarginLeft = parseFloat(window.getComputedStyle(pill).marginLeft);
                const pillMarginRight = parseFloat(window.getComputedStyle(pill).marginRight);
                const pillPaddingLeft = parseFloat(window.getComputedStyle(pill).paddingLeft);
                const pillPaddingRight = parseFloat(window.getComputedStyle(pill).paddingRight);
                transformX += pillWidth + pillMarginRight + pillPaddingLeft + pillPaddingRight + pillMarginLeft;
            }

            pillsContainer.style.setProperty('--transform-x', `${transformX}px`);
            // Delay the width calculation to ensure accurate measurement
            setTimeout(() => {
                const recalculatedWidth = activePillElement.offsetWidth;
                colorIndicator.style.width = `${recalculatedWidth}px`;
            }, 100);
            colorIndicator.style.height = `${pillHeight}px`;
            colorIndicator.style.transform = `translateX(${transformX}px)`;
        }
    }, [activePill]);

    const handlePillClick = (index, id) => {
        setActivePill(index);
        navigate(`/fm-resources/#${id}`); // Update the URL based on the clicked id
    };

    useEffect(() => {
        if (hash && hash.slice(1) !== '') {
            // Handle other hash values
            const hashValue = hash.slice(1);
            const index = pills.findIndex(item => item.id === hashValue);
            if (index !== -1) {
                const pillElement = pillRefContainer.current[hashValue];
                if (pillElement) {
                    setTimeout(() => {
                        pillElement.click();
                        const element = document.getElementById('resources-section');
                        if (element) {
                            setTimeout(() => {
                                element.scrollIntoView({ behavior: 'smooth' });
                            }, 0);
                        }
                    }, 800);
                }
            }
        } else {
            navigate(`/fm-resources/#whitepapers`);
        }
    }, []);

    const renderPills = () => {
        return pills.map((item, index) => (
            <li
                className={`nav-item`}
                role="presentation"
                key={item.id}
                onClick={() => handlePillClick(index, item.id)}
                ref={ref => {
                    pillRefContainer.current[item.id] = ref;
                }}
            >
                <button
                    className={`nav-link poppins-regular ${activePill === index ? 'active' : ''}`}
                    id={`pills-${item.id}-tab`}
                    data-bs-toggle="pill"
                    data-bs-target={`#pills-${item.id}`}
                    type="button"
                    role="tab"
                    aria-controls={`pills-${item.id}`}
                    aria-selected={activePill === index}
                >
                    {item.label}
                </button>
            </li>
        ));
    };
    return (
        <>
            <div>
                <Helmet>
                    <title>Resources | Fiber Mountain</title>
                    <meta name="description" content="Take a look at our different resources to get a better understanding of our products and solutions." />
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div className="glc-resources-wrapper bg-white">
                <div className="hero position-relative">
                    {/* Nav Start */}
                    <Nav />
                    {/* Nav End */}
                    <div className="hero-content bg-pink d-flex align-items-center flex-column position-relative justify-content-center">
                        <h1 className="poppins-regular text-white text-center">Resource Library</h1>
                        <h6 className="text-white poppins-regular resource-hero-info justify-content-center">Explore our knowledge base to discover more about our software and hardware solutions.</h6>
                            <a
                                href="https://docs.fibermountain.com/" target="_blank"
                                className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color btn-doc-portal">
                                <span data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                                    DOCUMENTATION PORTAL
                                </span>
                            </a>
                    </div>
                    <div className="container">
                        <div className="featured-content-wrapper bg-white">
                            <h2 className="poppins-regular text-center heading-featured-content text-black">Featured Content</h2>
                            <Slider {...settings}>

                                {Resources.glcFeaturedContent.map((value, index) => {
                                    return (
                                        <div className="slide-item" key={index}>
                                            <div className="product-element bg-pink text-center m-3">
                                                <div className="label"><p className="text-white dmsans-regular">{value.label}</p></div>
                                                <h4 className="poppins-semi-bold text-element font-weight-600 text-black"><span
                                                    dangerouslySetInnerHTML={{ __html: value.title }}></span></h4>
                                                <p className="text-black product-text dmsans-regular">{value.info}</p>
                                                <Link to={value.link} target="_blank" className="btn secondary-btn dmsans-regular  text-white border-primary-color mt-2 btn-read-more">LEARN MORE</Link>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                        <div className="glc-resources" id="resources-section">
                            <h2 className="text-black poppins-regular text-center">Resources by Fiber Mountain</h2>
                            <div className="row">
                                <div className={`col-12 mx-auto resources-pills-wrapper`}>
                                    <ul ref={pillsRef} className={`nav nav-pills mb-3`} id="pills-tab" role="tablist">
                                        {renderPills()}
                                        <div
                                            ref={colorIndicatorRef}
                                            className={'colorIndicator'}
                                            style={{ transform: `translateX(var(--transform-x))`, width: 'var(--width)' }}
                                        />
                                    </ul>
                                </div>
                            </div>

                            <div className="tab-content" id="pills-tabContent">
                                <div className={`tab-pane fade ${activePill === 0 ? 'show active' : ''}`} id="pills-whitepapers" role="tabpanel" aria-labelledby="pills-whitepapers-tab">
                                    <div className="row resources-wrapper">
                                        {Resources.glcWhitepapers.map((value, index) => {
                                            return (
                                                <div className="col-xl-4 col-md-6 col-sm-12 mb-md-5 mb-0" key={index}>
                                                    <div className="resource-element whitepaper bg-pink">
                                                        <img className="mw-100" src={value.icon} />
                                                        <h6 className="text-black poppins-semi-bold resource-element-heading" dangerouslySetInnerHTML={{ __html: value.title }}></h6>
                                                        <p className="text-black product-text dmsans-regular">{value.info}</p>
                                                        <Link target="_blank" to={value.pdfLink} className="btn secondary-btn dmsans-regular bg-primary-color text-white border-primary-color mt-2 btn-read-more btn-download">Download PDF</Link>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                                <div className={`tab-pane fade ${activePill === 1 ? 'show active' : ''}`} id="pills-datasheets" role="tabpanel" aria-labelledby="pills-datasheets-tab">
                                    <div className="row resources-wrapper">
                                        {Resources.glcDataSheets.map((value, index) => {
                                            return (
                                                <div className="col-xl-4 col-md-6 col-sm-12 mb-md-5 mb-0" key={index}>
                                                    <div className="resource-element datasheet bg-pink">
                                                        <img className="mw-100 image-height" src={value.icon} alt= {value.info} />
                                                        <h6 className="poppins-regular resource-element-heading" dangerouslySetInnerHTML={{ __html: value.title }}></h6>
                                                        <p className="product-text dmsans-regular">{value.info}</p>
                                                        <Link target="_blank" to={value.pdfLink} className="btn secondary-btn dmsans-regular bg-primary-color text-white border-primary-color mt-2 btn-read-more btn-download">Download PDF</Link>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>

                                </div>
                                {/* <div className={`tab-pane fade ${activePill === 2 ? 'show active' : ''}`} id="pills-solution-briefs" role="tabpanel" aria-labelledby="pills-solution-briefs-tab">
                                    <div className="row resources-wrapper">
                                        {Resources.glcSolutionBriefs.map((value, index) => {
                                            return (
                                                <div className="col-xl-4 col-md-6 col-sm-12 mb-md-5 mb-0" key={index}>
                                                    <div className="resource-element solution-brief m-3">
                                                        <img className="icon-max-height" src={value.icon} />
                                                        <h4 className="text-white poppins-regular resource-element-heading" dangerouslySetInnerHTML={{ __html: value.title }}></h4>
                                                        <p className="text-white product-text dmsans-regular">{value.info}</p>
                                                        <Link target="_blank" to={value.pdfLink} className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color mt-2 btn-read-more btn-download">Download PDF</Link>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div> */}
                                <div className={`tab-pane fade ${activePill === 2 ? 'show active' : ''}`} id="pills-ebooks" role="tabpanel" aria-labelledby="pills-ebooks-tab">
                                    <div className="row resources-wrapper">
                                        {Resources.glcEBooks.map((value, index) => {
                                            return (
                                                <div className="col-xl-4 col-md-6 col-sm-12 mb-md-5 mb-0" key={index}>
                                                    <div className="resource-element ebook bg-pink">
                                                        <img className="icon-max-height" src={value.icon} />
                                                        <h6 className="poppins-regular resource-element-heading" dangerouslySetInnerHTML={{ __html: value.title }}></h6>
                                                        <p className="product-text dmsans-regular">{value.info}</p>
                                                        <Link target="_blank" to={value.pdfLink} className="btn secondary-btn dmsans-regular bg-primary-color text-white border-primary-color mt-2 btn-read-more btn-download">Download PDF</Link>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className={`tab-pane fade ${activePill === 3 ? 'show active' : ''}`} id="pills-videos" role="tabpanel" aria-labelledby="pills-videos-tab">
                                    <div className="row resources-wrapper">
                                        {Resources.glcVideos.map((value, index) => {
                                            const modalName = value.title.replace(/[^a-zA-Z0-9]+/g, '-').replace(/^-+|-+$/g, '').replace(/\s+/g, '-');
                                            return (
                                                <div className="col-xl-4 col-md-6 col-sm-12 mb-md-5 mb-4" key={index}>
                                                    <div className="resource-element video-element bg-pink">
                                                        <img className="w-100 cursor-pointer" src={value.thumbnail} data-bs-target={'#' + modalName} data-bs-toggle="modal" />
                                                        <h6 className="poppins-regular resource-element-heading" dangerouslySetInnerHTML={{ __html: value.title }}></h6>
                                                        <p className="product-text dmsans-regular">{value.info}</p>
                                                    </div>
                                                    <VideoModal options={{ videoLink: value.videoLink, modalName }} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            {/* Blog Section Start */}
                            {/* <Blog /> */}
                            {/* Blog Section End */}
                        </div>
                    </div>
                </div>
                {/* Footer Start */}
                <Footer />
                {/* Footer End */}
            </div >
        </>
    );
}